import React from 'react';
import PrimaryButton from '../../common/components/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { APP_PATH } from '../../pathNames';
import styled from 'styled-components';
import { FaUpload } from 'react-icons/fa6';
import { scrollToTopSmoothly } from '../../helpers';
import { desktopMediaQuery } from '../../styleHelpers';

interface CTAButtonProps {
  alternativeText?: boolean;
}

const CTAButton: React.FC<CTAButtonProps> = ({ alternativeText }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(APP_PATH);
    scrollToTopSmoothly();
  };

  return (
    <Container>
      <PrimaryButton onClick={handleClick}>
        {alternativeText ? '✨ ' : <FaUpload />}

        {alternativeText ? 'Try now' : 'Upload Image'}
      </PrimaryButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;

  max-width: 300px;
  max-height: 50px;
  margin-top: 32px;
  margin-bottom: 0px;

  ${desktopMediaQuery} {
    margin-bottom: 32px;
  }
`;

export default CTAButton;
