//  const { generatedImageUrls } = useGeneralContext();
import React from 'react';
import { useGeneralContext } from '../../../context/GeneralContextProvider';
import { StyledImage } from './StyledImage';
import styled from 'styled-components';
import { desktopMediaQuery, getIsOnDesktop } from '../../../styleHelpers';
import { BORDER_COLOR } from '../../../constants';
import PrimaryButton from '../../../common/components/PrimaryButton';
import { waitSeconds, scrollToTopSmoothly } from '../../../helpers';

const ImageHistory: React.FC = () => {
  const { undressedImages } = useGeneralContext();

  if (undressedImages.length === 0) return <EmptySpace />;

  const isOnDesktop = getIsOnDesktop();

  const textToRender = isOnDesktop
    ? 'right-clicking on the image'
    : 'long-pressing on the image';

  const handleGoBack = async () => {
    window.history.back();
    await waitSeconds(0.3);
    scrollToTopSmoothly();
  };

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const target = event.target as HTMLImageElement;
    target.style.display = 'none'; // hide the image
  };
  return (
    <Container>
      <TitleText>Image History</TitleText>
      <SubtitleText>
        The images are cleared as your session ends. You can download them by
        {' ' + textToRender}
      </SubtitleText>

      {undressedImages.map((image, index) => (
        <StyledImage key={index} src={image.imageUrl} alt={`Image ${index}`} 
        onError={handleImageError}
        />
      ))}

      <PrimaryButton onClick={handleGoBack}>Go back</PrimaryButton>
    </Container>
  );
};

const EmptySpace = styled.div`
  height: 400px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 16px;

  padding-top: 32px;
  border-top: 0.5px solid ${BORDER_COLOR};
`;

const TitleText = styled.h2`
  font-size: 24px;
  font-weight: 700;
`;

const SubtitleText = styled.p`
  font-size: 16px;
  font-weight: 400;
  white-space: pre-wrap;
  max-width: 300px;
  ${desktopMediaQuery} {
    max-width: 400px;
  }
  
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 0;
  margin-bottom: 32px;
`;

export default ImageHistory;
