import { useEffect, useState } from 'react';
import {
  FormContainer,
  IconAndInputContainer,
  SingleLineInputField,
} from './authSharedComponents';
import {
  RiEyeCloseLine,
  RiEyeLine,
  RiLockUnlockLine,
  RiUserLine,
} from 'react-icons/ri';
import styled from 'styled-components';
import PrimaryButton from '../../common/components/PrimaryButton';
import usePasswordVisibility from './usePasswordVisibility';
import { toast } from 'react-toastify';
import { BounceLoader } from 'react-spinners';
import useApi from '../../common/hooks/useApi';
import UserTokenStorage from '../../common/storages/userTokenStorage';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import ReferralStorage from '../../common/storages/referralStorage';
import { trackAskedToSignup, trackSignedUp } from '../../analytics';
import { FaUserPlus } from 'react-icons/fa';

interface I {
  closeModal: () => void;
}

const SignUpContent = ({ closeModal }: I) => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const { eyeIcon, handleClickOnEye } = usePasswordVisibility('password');

  const { apiCall, loading } = useApi('signup', 'POST');

  const { setLoggedInUserToken, setShowBuyCreditsModal } = useGeneralContext();

  const handleSignup = async (username: string, password: string) => {
    if (loading) return;
    if (username.length < 4 || password.length < 4) {
      toast.error('Username and password must be at least 4 characters long');
      return;
    }
    const referralStorage = new ReferralStorage();
    const referralId = referralStorage.getReferralId();
    const response = await apiCall({ username, password, referralId });

    const token = response?.data?.token;

    if (response.status !== 201 || !token) {
      toast.error('Error signing up. Please try again.');
      return;
    }

    const userTokenStorage = new UserTokenStorage();
    userTokenStorage.setToken(token);
    setLoggedInUserToken(token);
    closeModal();
    toast.success('Signed up successfully');
    trackSignedUp();
    setShowBuyCreditsModal(true);
  };

  useEffect(() => {
    trackAskedToSignup();
  }, []);

  const EYE_ICON_SIZE = 17;
  return (
    <div>
      <h2>Create an Account</h2>
      <FormContainer>
        <IconAndInputContainer>
          <RiUserLine size={32} />
          <SingleLineInputField
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </IconAndInputContainer>

        <IconAndInputContainer
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSignup(username, password);
            }
          }}
        >
          <RiLockUnlockLine size={32} />
          <SingleLineInputField
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            id="password"
          />
          <EyeIconContainer>
            {eyeIcon === 'closed' ? (
              <RiEyeCloseLine size={EYE_ICON_SIZE} onClick={handleClickOnEye} />
            ) : (
              <RiEyeLine size={EYE_ICON_SIZE} onClick={handleClickOnEye} />
            )}
          </EyeIconContainer>
        </IconAndInputContainer>
      </FormContainer>

      <PrimaryButton onClick={() => handleSignup(username, password)}>
        {loading ? (
          <BounceLoader color={'white'} size={24} />
        ) : (
          <>
            <FaUserPlus />
            Sign Up
          </>
        )}
      </PrimaryButton>
    </div>
  );
};

const EyeIconContainer = styled.div`
  margin-top: 4px;
  margin-right: -23px;
  margin-left: -6px;
`;

export default SignUpContent;
