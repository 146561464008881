import { useEffect } from 'react';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import useApi from './useApi';
import { waitSeconds } from '../../helpers';
/**
 * Custom React hook for handling user data.
 *
 * This hook fetches the user data using the 'getUser' endpoint and updates the amount of credits in the context.
 * It shows an error toast if the API call fails or the response is not as expected.
 * The API call is made whenever the `loggedInUserToken` changes.
 */
const useGetUser = (): void => {
  const { loggedInUserToken, amountOfCredits: creditsInContext, setAmountOfCredits, fetchUserDataEveryNSeconds , setFetchUserDataEveryNSeconds} = useGeneralContext();
  const { apiCall } = useApi('getUser', 'POST');

  const handleUserData = async () => {
    if (!loggedInUserToken || loggedInUserToken === '') return;

    await waitSeconds(1);
    try {
      const response = await apiCall({ token: loggedInUserToken });
      const amountOfCredits = response?.data?.amountOfCredits;
      if (response.status !== 200 || amountOfCredits === null) {
        return;
      }

      if (amountOfCredits !== creditsInContext) {
        setFetchUserDataEveryNSeconds(60);
      }
      setAmountOfCredits(amountOfCredits);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleUserData(); 
    const intervalId = setInterval(handleUserData, fetchUserDataEveryNSeconds * 1000);
  
    return () => {
      clearInterval(intervalId); 
    };
  }, [loggedInUserToken]);
};

export default useGetUser;
