import React from 'react';

import styled from 'styled-components';
import { PRIMARY_COLOR, PRIMARY_TEXT_COLOR } from '../../constants';
import { desktopMediaQuery } from '../../styleHelpers';

const TitleAndSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 8px;

`;

const TitleContainer = styled.h1`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  margin-bottom: 0;
  margin-top: 8px;

  ${desktopMediaQuery} {
    font-size: 58px;
  }


  ${desktopMediaQuery} {
    margin-top: 32px;
  }
`;

const TitleWithGradient = styled.div`
  background: -webkit-linear-gradient(
    45deg,
    ${PRIMARY_TEXT_COLOR},
    ${PRIMARY_COLOR}
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Subtitle = styled.h2`
  text-align: center;
  color: ${PRIMARY_TEXT_COLOR};
  font-size: 20px;

  margin-top: 0;

  ${desktopMediaQuery} {
    font-size: 24px;
  }
`;

const TitleAndSubtitle: React.FC = () => {
  return (
    <TitleAndSubtitleContainer>
      <TitleContainer>
        <TitleWithGradient>AI Nudes.io </TitleWithGradient>
        😈
      </TitleContainer>
      <Subtitle>Undress images using AI</Subtitle>
    </TitleAndSubtitleContainer>
  );
};

export default TitleAndSubtitle;
