import { useState } from 'react';
import { getBaseUrlForFetching } from '../../App';

interface ApiCallResponse {
  status: number;
  data?: any; // TODO: type this
}

/**
 * Custom hook for making API calls.
 *
 * @template T The expected return type of the API call.
 * @param {string} endpoint The API endpoint to call.
 * @param {'GET' | 'POST' | 'PUT' | 'DELETE'} method The HTTP method to use for the API call.
 * @returns {Object} An object containing the `apiCall` function and the `loading` state.
 * @returns {Function} apiCall The function to call the API. Accepts an optional body parameter for the request.
 * @returns {boolean} loading The loading state of the API call. Is `true` while the API call is in progress and `false` otherwise.
 *
 * @example
 * const { apiCall, loading } = useApi<{ token: string }>('login', 'POST');
 * const response = await apiCall({ username: 'test', password: 'test' });
 */
const useApi = (
  endpoint: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
) => {
  const [loading, setLoading] = useState(false);

  const apiCall = async (body?: any): Promise<ApiCallResponse> => {
    setLoading(true);

    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${endpoint}`;
    try {
      const response = await fetch(fetchUrl, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        console.error(`Failed to ${method} ${endpoint}`);
        return {
          status: response.status,
        };
      }

      const data = await response.json();

      return {
        status: response.status,
        data
      }
    } catch (err) {
      console.error(err);
      return {
        status: 500,
      };
    } finally {
      setLoading(false);
    }
  };

  return { apiCall, loading };
};

export default useApi;
