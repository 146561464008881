import React from 'react';
import styled from 'styled-components';
import BaseModal from './BaseModal';
import PrimaryButton from '../common/components/PrimaryButton';
import { LOCAL_STORAGE_EXAMPLE_INPAINT_SEEN } from '../constants';

interface I {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const ExampleInpaintModal: React.FC<I> = ({ showModal, setShowModal }) => {
  const handleClose = () => {
    localStorage.setItem(LOCAL_STORAGE_EXAMPLE_INPAINT_SEEN, 'true');
    setShowModal(false);
  };

  return (
    <BaseModal showModal={showModal} onRequestClose={handleClose}>
      <ModalContent>
        <ExampleImageToUpload src="exampleInpaint.gif" alt="Example Image" />
        Paint over the area you want to remove
        <PrimaryButton onClick={handleClose}>OK</PrimaryButton>
      </ModalContent>
    </BaseModal>
  );
};
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const ExampleImageToUpload = styled.img`
  width: 100%;
  height: auto;
  max-width: 70vw;
  max-height: 70vh;
  width: auto;
`;

export default ExampleInpaintModal;
