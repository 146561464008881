import { ImageSize } from './types';

export const MIN_DIMENSIONS = 300;

export const DEFAULT_UNDRESS_PROMPT =
  'RAW photo of a (nude:1.4) woman, small nipples, high quality, (perfect symmetrical beautiful vagina:1.2). (perfect vulva:1.3)';
export const DEFAULT_UNDRESS__NEG_PROMPT =
  '(disfigured deformed ugly vagina:1.3), (disfigured, deformed, morphed:1.3), (blurry) (oversaturated), (wearing clothes:1.1), (clothe artifacts) (dark spot:1.2) fake boobs, poorly drawn, bad anatomy, semi-realistic, cgi';

// TODO, can be maybe removed
export const DEFAULT_NEGATIVE_PROMPT =
  '((deformed face)), ((disfigured)), ((bad art)), ((deformed)), ((extra limbs)), ((close up)), blurry, (((duplicate))), ((morbid)), ((mutilated)), (out of frame), extra fingers, mutated hands, ((poorly drawn hands)), ((poorly drawn face)), (((mutation))), (((deformed))), blurry, ((bad anatomy)), (watermark:1.2), (disfigured face and eyes:1.1)';

export const DEFAULT_PORN_NEGATIVE_PROMPT =
  '((deformed face)), ((disfigured)), ((bad art)), ((deformed)), (deformed fingers:1.2), (disfigured face and eyes:1.1)';
//"((deformed face)), ((out of frame)), ((extra fingers)), mutated hands, ((poorly drawn hands)), ((poorly drawn face)), (((mutation))), (((deformed))), (((tiling))), blurry, ((bad anatomy)), ((bad proportions)), ((extra limbs)), cloned face, glitchy, ((double torso)), ((extra arms)), ((extra hands)), ((mangled fingers)), (missing lips), ((extra legs)), (extra nipples), watermark";
export const NEGATIVE_PROMPT_FOR_NEW_PHOTO =
  '((deformed face)), ((disfigured)), ((bad art)), ((deformed)), (disfigured face and eyes:1.1)';

export const DEFAULT_URLS = [
  'https://d1okzptojspljx.cloudfront.net/generations/ec94b280-5ef8-414b-ade8-03576fee91e9-0.png',
  'https://d1okzptojspljx.cloudfront.net/generations/d085427a-b43b-4945-ab88-ba8215a46250-0.png',
  'https://d1okzptojspljx.cloudfront.net/generations/c4fe9ec7-1d78-404f-bf21-a4447894a84e-0.png',
];

export const DEFAULT_SIZE: ImageSize = { width: 512, height: 640 };
export const TALL_PORTRAIT_SIZE: ImageSize = { width: 512, height: 768 };

export const PRIMARY_COLOR = '#a83fbb' 
export const DARKER_PRIMARY_COLOR = '#642f70';
export const GOLD_COLOR = '#FFD700';
export const HOVER_COLOR = DARKER_PRIMARY_COLOR;
export const BACKGROUND_COLOR = '#0a192f';
export const CARD_BACKGROUND_COLOR = '#172a45';
export const ORANGE_LINK_COLOR = '#ff7f50';

export const LIGHTER_BG_FOR_TOAST = '#170d28';
//export const PRIMARY_COLOR = '#B22222';

//d707a4 in rbga = 215, 7, 164

export const GREEN_COLOR = '#00FF00';

export const PRIMARY_TEXT_COLOR = '#F0F0F0';
export const ICON_COLOR = PRIMARY_TEXT_COLOR;

export const SECONDARY_TEXT_COLOR = '#a8a8a8';
export const BORDER_COLOR = SECONDARY_TEXT_COLOR;

export const LIGHTER_GREY_COLOR = '#a3a3a3';

export const EVEN_LIGHTER_GREY_COLOR = '#e3e3e3';

export const LIGHT_GREY_COLOR = '#303134';
export const LIGHTER_GREY_FOR_HOVER_EFFECT = '#1f1a2b';

export const DARK_GREY_COLOR = '#1f1a2b';


export const DARKER_GOLD_COLOR = '#FFC400';

export const previewBaseBaseUrl =
  'https://deploy-preview-59--onlyfakes.netlify.app';

export const PREVIEW_URL_FOR_FUNCTIONS = `${previewBaseBaseUrl}/.netlify/functions`;

export const BASE_URL = 'https://ainudes.io';
export const BASE_URL_FOR_FUNCTIONS = `${BASE_URL}/.netlify/functions`;

export const FEED_BASE_URL = `${BASE_URL}/app/feed`;

export const MAX_WIDTH = 445;

export const DESKTOP_WIDTH = 768;

export const MS_IN_SECOND = 1000;

export const LOCAL_STORAGE_ACCEPTED_TERMS_KEY = 'acceptedTerms';
export const LOCAL_STORAGE_EXAMPLE_IMAGE_SEEN_KEY = 'exampleImageSeen';
export const LOCAL_STORAGE_EXAMPLE_INPAINT_SEEN = 'exampleInpaintSeen';