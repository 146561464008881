import React from 'react';
import {
  CardContainer,
  CardTitle,
  CardText,
} from '../../common/components/cardParts';

const HowDoYouUseIt: React.FC = () => {
  return (
    <CardContainer>
      <CardTitle>How to use the app?</CardTitle>
      <CardText>
        <li>📤 Upload an image you have the right to use</li>
        <li>🖌️ Select the area to nudify</li>
        <li>🧍‍♀️ Choose body type</li>
        <li>✨ Wait a few sec while the AI works</li>
        <li>💦 Enjoy the image</li>
      </CardText>
    </CardContainer>
  );
};

export default HowDoYouUseIt;
