import { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { desktopMediaQuery } from '../../styleHelpers';

const ExampleImage = ({
  editImageExamples,
}: {
  editImageExamples: string[];
}) => {
  const currentIndex = useUpdateImages(editImageExamples, 2500);

  return (
    <Container key={currentIndex}>
      <StyledImg
        src={editImageExamples[currentIndex]}
        key={currentIndex}
        alt="Example image"
      />
    </Container>
  );
};

const useUpdateImages = (exampleImages: any[], timePerImage: number) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const imageRefs = useRef(exampleImages.map(() => new Image()));

  useEffect(() => {
    imageRefs.current.forEach((img, index) => {
      img.src = exampleImages[index];
    });
  }, [exampleImages]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % exampleImages.length);
    }, timePerImage);

    return () => clearTimeout(timer);
  }, [currentIndex, exampleImages]);

  return currentIndex;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.65;
  }
  100% {
    opacity: 1;
  }
`;

const StyledImg = styled.img`
  animation: ${fadeIn} 0.2s ease-in-out;

  // 512 x 768 scaled down
  height: 420px;
  width: 280px;

  ${desktopMediaQuery} {
    height: 512px;
    width: 341px;
  }

  margin-bottom: 16px;

  border-radius: 16px;
`;

export default ExampleImage;
