import styled from 'styled-components';
import { BORDER_COLOR, GOLD_COLOR, PRIMARY_COLOR } from '../../constants';
import BuyButton from '../../pages/LandingPage/BuyButton';
import { desktopMediaQuery } from '../../styleHelpers';
import { CardContainer, CardText, CardTitle, PricePart } from './cardParts';

interface P {
  onLandingPage?: boolean;
}

// Remember to change on Picarus if changing these
const PaymentComponent = ({ onLandingPage }: P) => {
  const dontShowFree = !onLandingPage;
  return (
    <CardsContainer dontShowFree={dontShowFree}>
      {!dontShowFree && (
        <PriceCardContainer>
          <CardTitle>Free</CardTitle>
          <PricePart>$0</PricePart>
          Blurred version of the image
          <BuyButton variation="free" onLandingPage={onLandingPage} />
        </PriceCardContainer>
      )}

      <PriceCardContainer>
        {dontShowFree && <EmptySpace />}
        <CardTitle>Small package</CardTitle>
        <PricePart>$9.90</PricePart>
        10 images
        {dontShowFree && <EmptySpace />}
        <BuyButton variation="small" onLandingPage={onLandingPage} />
      </PriceCardContainer>

      <MediumPackageCard>
        <ExtraHintText>Most popular 🌟</ExtraHintText>
        <CardTitle>Medium package</CardTitle>
        <PricePart>$19.90</PricePart>
        30 images
        <SaveXText>Save -33%!</SaveXText>
        <BuyButton variation="medium" onLandingPage={onLandingPage} />
      </MediumPackageCard>

      <PriceCardContainer>
        <ExtraHintText>Best Deal 💰</ExtraHintText>
        <CardTitle>Large package</CardTitle>
        <PricePart>$49.90</PricePart>
        100 images
        <SaveXText>Save -50%!</SaveXText>
        <BuyButton variation="large" onLandingPage={onLandingPage} />
      </PriceCardContainer>
    </CardsContainer>
  );
};

export default PaymentComponent;

const CardsContainer = styled.div<{ dontShowFree?: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 8px;

  ${desktopMediaQuery} {
    grid-template-columns: ${(props) =>
      props.dontShowFree
        ? 'repeat(auto-fill, minmax(300px, 1fr))'
        : 'repeat(2, 1fr)'};
    gap: ${(props) => (props.dontShowFree ? '8px' : '24px')};

    /* Always show scrollbar */
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: ${BORDER_COLOR};
    }
    overflow-y: auto;
  }
`;

const PriceCardContainer = styled(CardContainer)`
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 4px;
  margin-right: auto;

  max-width: 300px;

  ${desktopMediaQuery} {
    max-width: 350px;
    max-height: 30vh;
    margin-left: 32px;
  }
`;

const MediumPackageCard = styled(PriceCardContainer)`
  border: 2px solid ${PRIMARY_COLOR};
`;

const SaveXText = styled(CardText)`
  color: ${GOLD_COLOR};
`;

const ExtraHintText = styled(CardText)`
  color: ${GOLD_COLOR};
  font-size: 18px;
  font-weight: bold;
  margin-top: -12px;
  margin-bottom: 12px;
`;

const EmptySpace = styled.div`
  ${desktopMediaQuery} {
    height: 10%;
  }
`;
