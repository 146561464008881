import styled from 'styled-components';
import { PRIMARY_COLOR } from '../../../../constants';
import { desktopMediaQuery } from '../../../../styleHelpers';

interface BrushSizeSetterProps {
  brushSize: number;
  setBrushSize: (size: number) => void;
}

const BrushSizeSetter: React.FC<BrushSizeSetterProps> = ({
  setBrushSize,
  brushSize,
}) => {
  return (
    <Container>
      <Wrapper>
        Brush size 🖌️
        <StyledSlider
          type="range"
          min="10"
          max="150"
          value={brushSize}
          onChange={(e) => setBrushSize(Number(e.target.value))}
        />
      </Wrapper>
      <BrushSizeIndicator brushSize={brushSize} />
    </Container>
  );
};

export default BrushSizeSetter;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 16px;

  width: 100%;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 10px;
  padding: 12px;
  font-weight: 600;
  white-space: nowrap;
`;

const StyledSlider = styled.input`
  width: 100%;

  ${desktopMediaQuery} {
    width: 256px;
  }

  -webkit-appearance: none;
  appearance: none;
  height: 25px;
  border-radius: 5px;
  background: transparent;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  border: 2px solid ${PRIMARY_COLOR};

  margin-top: -2px;
`;

const BrushSizeIndicator = styled.div<{ brushSize: number }>`
  margin: 0 auto;
  width: ${(p) => p.brushSize}px;
  height: ${(p) => p.brushSize}px;
  border-radius: ${(p) => p.brushSize / 2}px;
  border: 2px solid ${PRIMARY_COLOR};
  background-color: transparent;
`;
