import styled from 'styled-components';
import TitleAndSubtitle from './TitleAndSubtitle';
import ExampleImage from './ExampleImage';
import CTAButton from './CTAButton';
import HowItWorks from './HowItWorks';
import HowDoYouUseIt from './HowDoYouUseIt';
import SafetyInfo from './SafetyInfo';
import { desktopMediaQuery } from '../../styleHelpers';
import { DESKTOP_WIDTH, SECONDARY_TEXT_COLOR } from '../../constants';
import PricingInfo from './PricingInfo';
import BasicContainerWithFadeIn from '../../common/components/BasicContainerWithFadeIn';
import { fadeIn } from '../../common/animations';
import FeaturedOn from './FeaturedOn';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import OfflineAccess from '../../OfflineAccess';

const LandingPage = () => {
  const { siteOffline } = useGeneralContext();
  const firstExample = ['example1-dressed.jpeg', 'example1-undressed.jpeg'];
  const secondExample = ['example2-dressed.jpeg', 'example2-undressed.jpeg'];
  const thirdExample = ['example3-undressed.jpeg', 'example3-dressed.jpeg'];
  const fourthExample = ['example4-dressed.jpeg', 'example4-undressed.jpeg'];

  const isOnDesktop = window.innerWidth > DESKTOP_WIDTH;

  if (siteOffline) {
    return <OfflineAccess />;
  }
  return (
    <Container>
      <TitleAndSubtitle />
      <ContainerForFirstExample>
        <ExampleImage editImageExamples={firstExample} />
        <TextAndButtonContainer>
          <CTAButton />
          {isOnDesktop && <>Quick results, guaranteed privacy - Try now</>}
        </TextAndButtonContainer>
      </ContainerForFirstExample>

      <ResponsiveContainer>
        <HowItWorks />
        <ExampleImage editImageExamples={secondExample} />
      </ResponsiveContainer>

      <CTAButton alternativeText />

      <ResponsiveContainer>
        {isOnDesktop ? (
          <>
            <ExampleImage editImageExamples={thirdExample} />
            <HowDoYouUseIt />
          </>
        ) : (
          <>
            <HowDoYouUseIt />
            <ExampleImage editImageExamples={thirdExample} />
          </>
        )}
      </ResponsiveContainer>

      <CTAButton alternativeText />

      <ResponsiveContainer>
        <SafetyInfo />
        <ExampleImage editImageExamples={fourthExample} />
      </ResponsiveContainer>

      <PricingInfo />

      {!isOnDesktop && <CTAButton alternativeText />}

      <FeaturedOn />
    </Container>
  );
};

const Container = styled(BasicContainerWithFadeIn)`
  width: 95vw;
  animation: ${fadeIn} 1.5s ease-in-out;
`;

const ContainerForFirstExample = styled.div`
  display: flex;
  flex-direction: column;

  ${desktopMediaQuery} {
    flex-direction: row;
    justify-content: center;
    gap: 24px;
  }
`;

const TextAndButtonContainer = styled.div`
  display: flex;
  color: ${SECONDARY_TEXT_COLOR};
  align-items: center;
  flex-direction: column;

  max-width: 80vw;

  gap: 16px;
  ${desktopMediaQuery} {
    justify-content: center;
  }
`;

const ResponsiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 650px;

  ${desktopMediaQuery} {
    flex-direction: row;
    justify-content: center;
    gap: 5vw;
    max-width: 80vw;
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

export default LandingPage;
