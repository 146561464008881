import React from 'react';
import {
  CardContainer,
  CardTitle,
  CardText,
} from '../../common/components/cardParts';

const SafetyInfo: React.FC = () => {
  return (
    <CardContainer>
      <CardTitle>Is it safe?</CardTitle>
      <CardText>
        Your privacy is our priority. Images are deleted within a week, and we
        never share them. You remain anonymous, and we don't keep payment
        details. Our European base ensures adherence to stringent privacy laws,
        and we employ Fortune 500-level security to protect your data
      </CardText>
    </CardContainer>
  );
};

export default SafetyInfo;
