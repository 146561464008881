import { UndressedImage } from '../../../../types';
import { useGeneralContext } from '../../../../context/GeneralContextProvider';
import ImageStorage from '../../../../common/storages/imageStorage';

const useAddImageToContextAndStorage = () => {
  const { setUndressedImages } = useGeneralContext();
  const imageStorage = new ImageStorage();

  const addImageToContextAndStorage = (newImage: UndressedImage) => {
    setUndressedImages((prev) => {
      if (!prev.some((image) => image.imageUrl === newImage.imageUrl)) {
        return [newImage, ...prev];
      }
      return prev;
    });

    imageStorage.addImage(newImage);
  };

  return addImageToContextAndStorage;
};

export default useAddImageToContextAndStorage;
