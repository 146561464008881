import styled from 'styled-components';
import UploadImageBox from './UploadImageBox';
import {
  InstructionsContainer,
  InstructionsText,
  InstructionsTitle,
} from './commonComponents';
import { FaCropSimple, FaPerson, FaShirt } from 'react-icons/fa6';
import { MdHighQuality } from 'react-icons/md';

const UploadPart = () => {
  return (
    <Container>
      <InstructionsContainer>
        <InstructionsTitle>Step 1: Upload Image</InstructionsTitle>
        <InstructionsText>
          <p>
            <FaShirt size={'22px'} />She should wear minimal clothing; bikini is ideal
          </p>
          <p>
            <FaPerson size={'22px'} />Ensure she's standing and centered in the image
          </p>
          <p>
            <FaCropSimple size={'20px'} />Crop the image to include only her
          </p>
          <p>
            <MdHighQuality size={'20px'} />Upload a high-quality image
          </p>
        </InstructionsText>
      </InstructionsContainer>

      <UploadImageBox />
    </Container>
  );
};

const Container = styled.div`
  /* Add your CSS styles here */
`;

export default UploadPart;
