class UserTokenStorage {
  private storageKey: string;

  constructor() {
    this.storageKey = 'userToken';
  }

  getToken(): string {
    const token = localStorage.getItem(this.storageKey);
    return token ? JSON.parse(token) : '';
  }

  setToken(token: string): void {
    localStorage.setItem(this.storageKey, JSON.stringify(token));
  }

  removeToken(): void {
    localStorage.removeItem(this.storageKey);
  }
}

export default UserTokenStorage;
