import React, { useEffect, useState } from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import {
  LIGHTER_BG_FOR_TOAST,
  LOCAL_STORAGE_ACCEPTED_TERMS_KEY,
  PRIMARY_TEXT_COLOR,
} from '../../constants';
import { fadeIn } from '../../common/animations';
import ImageStorage from '../../common/storages/imageStorage';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import TopBar from './MainAppCommon/TopBar/TopBar';
import AcceptTermsModal from '../../modals/AcceptTermsModal';
import { scrollToTopSmoothly } from '../../helpers';
import AuthModal from '../../modals/AuthModal/AuthModal';
import useGetUser from '../../common/hooks/useGetUser';
import UserTokenStorage from '../../common/storages/userTokenStorage';
import BuyCreditsModal from '../../modals/BuyCreditsModal';
import OfflineAccess from '../../OfflineAccess';

const MainApp: React.FC = () => {
  const [showAcceptTermsModal, setShowAcceptTermsModal] = useState(false);

  const {
    setUndressedImages,
    showAuthModal,
    setShowAuthModal,
    showBuyCreditsModal,
    setShowBuyCreditsModal,
    setLoggedInUserToken,

    siteOffline,
  } = useGeneralContext();

  const imageStorage = new ImageStorage();
  const tokenStorage = new UserTokenStorage();

  useEffect(() => {
    const token = tokenStorage.getToken();
    if (token) {
      setLoggedInUserToken(token);
    }
  }, []);

  useGetUser();

  useEffect(() => {
    const images = imageStorage.getImages();
    setUndressedImages(images);
    scrollToTopSmoothly();
  }, []);

  useEffect(() => {
    const hasAcceptedTerms = localStorage.getItem(
      LOCAL_STORAGE_ACCEPTED_TERMS_KEY,
    );

    if (!hasAcceptedTerms) {
      setShowAcceptTermsModal(true);
    }
  }, []);

  if (siteOffline) {
    return (
     <OfflineAccess />
    );
  }

  return (
    <MainAppContainer>
      <StyledToastContainer
        position="top-center"
        hideProgressBar
        closeButton={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        draggablePercent={50}
      />

      <AcceptTermsModal
        showModal={showAcceptTermsModal}
        setShowModal={setShowAcceptTermsModal}
      />

      <AuthModal showModal={showAuthModal} setShowModal={setShowAuthModal} />

      <BuyCreditsModal
        showModal={showBuyCreditsModal}
        setShowModal={setShowBuyCreditsModal}
      />

      <TopBar />
      <EmptySpaceForTopBar />
      <Outlet />
    </MainAppContainer>
  );
};

const EmptySpaceForTopBar = styled.div`
  height: 64px;
`;

export const TIME_TO_CLOSE_TOAST = 2000;
// Default toast styles, "save folder" toast does overwrite this though
const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    border-radius: 8px;
    background: ${LIGHTER_BG_FOR_TOAST};
    box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.9);
    color: ${PRIMARY_TEXT_COLOR};
    padding: 8px;
  }

  .Toastify__toast.SaveToast {
    background: transparent;
    box-shadow: none;
  }
`;

const MainAppContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 98vw;
  height: 100%;

  // This causes the animation when the app is first loaded
  animation: ${fadeIn} 2s ease-in-out;
`;

export default MainApp;
