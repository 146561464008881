import React from 'react';
import styled from 'styled-components';
import PrimaryButton from '../../common/components/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { APP_PATH } from '../../pathNames';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { toast } from 'react-toastify';
import { trackClickBuy } from '../../analytics';
import { desktopMediaQuery } from '../../styleHelpers';

interface BuyButtonProps {
  variation: 'free' | 'small' | 'medium' | 'large';
  onLandingPage?: boolean;
}

const decodeToken = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
  return JSON.parse(jsonPayload);
};
export const PAYMENTS_OFFLINE = true;

const BuyButton: React.FC<BuyButtonProps> = ({ variation, onLandingPage }) => {
  const navigate = useNavigate();
  const {
    loggedInUserToken,
    setShowAuthModal,
    setShowBuyCreditsModal,
    setFetchUserDataEveryNSeconds,
  } = useGeneralContext();
  const handleClick = () => {
    if (PAYMENTS_OFFLINE) {
      toast.error('Payments are offline for now. Please try again later.');
      return;
    }

    if (onLandingPage) {
      navigate(APP_PATH);
      return;
    }
    if (variation === 'free') {
      navigate(APP_PATH);
      return;
    }
    if (!loggedInUserToken) {
      setShowBuyCreditsModal(false);
      toast.error('Please sign up to buy credits');
      setShowAuthModal(true);
      return;
    }

    trackClickBuy();

    const PICARUS_URL = 'https://picarus.ai';

    const userId = decodeToken(loggedInUserToken)?.id;
    if (!userId) {
      toast.error('Something went wrong. Try logging out and in again');
      return;
    }

    if (userId === "dd8bcb41-0971-405a-8f51-328c1483bf7f") {
      toast.error('Something went wrong. Try again later');
    }

    const paymentTypes = {
      small: 'small-ain',
      medium: 'medium-ain',
      large: 'large-ain',
    };
    const paymentType = paymentTypes[variation];

    alert(
      'A new tab will open for our secure payments page. Please ensure pop-ups are allowed. Return to this page after completing your payment.',
    );

    const USE_STRIPE = false;
    const urlToRedirect =
      `${PICARUS_URL}/redirect?userId=${userId}&paymentType=${paymentType}` +
      (USE_STRIPE ? '&processor=stripe' : '');
    setFetchUserDataEveryNSeconds(10);
    window.open(urlToRedirect, '_blank');
  };
  const textToRender = variation === 'free' ? 'Try now' : 'Buy now';
  return (
    <Container>
      <PrimaryButton onClick={handleClick}>{textToRender}</PrimaryButton>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 32px;
  margin-bottom: 8px;

  ${desktopMediaQuery} {
    margin-top: 16px;
    margin-bottom: 4px;
  }
`;

export default BuyButton;
