import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import useUploadImage from './useUploadImage';
import { MIN_DIMENSIONS } from '../../../../constants';
import { scrollToXSmoothly, waitSeconds } from '../../../../helpers';

/*
function getResizedFigures(
  naturalWidth: number,
  naturalHeight: number,
  maxSize: number,
  divisor: number = 32, // Add divisor parameter with a default value of 32
): { width: number; height: number } {
  const widthScaling = maxSize / naturalWidth;
  const heightScaling = maxSize / naturalHeight;
  let width: number;
  let height: number;

  // Check if resizing is needed
  if (naturalWidth > maxSize || naturalHeight > maxSize) {
    // Determine the scaling factor
    const scalingFactor = Math.min(widthScaling, heightScaling);
    // Apply scaling factor to both dimensions
    width = naturalWidth * scalingFactor;
    height = naturalHeight * scalingFactor;

    // Adjust dimensions to be divisible by the divisor
    width = width - (width % divisor);
    height = height - (height % divisor);

    return { width, height };
  } else {
    // Adjust original dimensions to be divisible by the divisor, if needed
    width = naturalWidth - (naturalWidth % divisor);
    height = naturalHeight - (naturalHeight % divisor);

    // Ensure dimensions don't get scaled up unintentionally
    width = Math.min(width, naturalWidth);
    height = Math.min(height, naturalHeight);

    return { width, height };
  }
}
*/

const maxSize = 768;
const maxFileSize = 15728640;

/**
 * THIS IS PRETTY MUCH JUST MAGIC TO HANDLE THE IMAGE UPLOADING, SORRY NEXT PERSON!
 *
 * Custom React hook for managing the upload of reference images. It handles resizing images to fit within a
 * maximum size limit, converts them to a base64-encoded format, and then uploads them using the useUpload hook.
 * The hook controls the upload process flow, including checking file size constraints, and resizing the image
 * if it exceeds the maximum dimensions. The resizing maintains the aspect ratio of the image. The hook also
 * manages the upload state, indicating when an upload is in progress.
 *
 * @param {function(string): void} setUploadedImageUrl - Callback function to set the URL of the successfully uploaded image.
 * @param {function(ImageSize): void} setSize - Callback function to set the dimensions of the resized image.
 * @returns {{ isUploading: boolean, handleImageUpload: function(React.ChangeEvent<HTMLInputElement>): void }}
 *          An object containing:
 *            - isUploading (boolean): A state variable indicating if the image is currently being uploaded.
 *            - handleImageUpload (function): A function to be invoked with an image input change event. This function
 *              performs validation on the selected file, resizes the image if necessary, converts it to base64, and
 *              then calls a custom image upload function. Post-upload, it updates the state with the URL and size of
 *              the uploaded image.
 */
const useHandleReferenceImageUploading = (
  setUploadedImageUrl: (url: string) => void,
) => {
  const uploadImage = useUploadImage();
  const [isUploading, setIsUploading] = useState(false);

  const handleImageUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) return;
      if (file.size > maxFileSize) {
        toast.error('File size must be less than 5MB');
        return;
      }
      const img = new Image();

      const url = URL.createObjectURL(file);

      img.src = url;
      img.onload = async () => {
        if (img.width < MIN_DIMENSIONS || img.height < MIN_DIMENSIONS) {
          alert(
            `Image dimensions should be at least ${MIN_DIMENSIONS}x${MIN_DIMENSIONS}`,
          );
          URL.revokeObjectURL(url); // Clean up memory
          return;
        }
        URL.revokeObjectURL(url);

        setIsUploading(true);
        const base64Image = await toBase64(file);
        const response = await uploadImage(base64Image);
        if (response.status === 200 && response.imageUrl) {
          setUploadedImageUrl(response.imageUrl);
          await waitSeconds(0.5)
          scrollToXSmoothly(570);
        }
        setIsUploading(false);
      };
    },
    [uploadImage, maxSize, maxFileSize],
  );

  const toBase64 = (file: File) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  return {
    isUploading,
    handleImageUpload,
  };
};

export default useHandleReferenceImageUploading;
