import {
  generateTrackId,
  headersForFetching,
  waitSeconds,
} from '../../../../helpers';
import { getBaseUrlForFetching } from '../../../../App';
import { useGeneralContext } from '../../../../context/GeneralContextProvider';
import { Age, BodyType, Breasts } from '../../../../types';
import { trackStartedUndressing } from '../../../../analytics';
import { toast } from 'react-toastify';
import { PAYMENTS_OFFLINE } from '../../../LandingPage/BuyButton';

interface Returns {
  status: number;
  imageUrl?: string;
}

const useUndressImage = () => {
  const { setTrackIdOfImageToGenerate, loggedInUserToken } =
    useGeneralContext();

  const { setShowAuthModal, amountOfCredits, setAuthModalActiveVariant } = useGeneralContext();

  const undressImage = async (
    imageToUndressUrl: string,
    maskImageUrl: string,
    width: number,
    height: number,
    breasts: Breasts,
    bodyType: BodyType,
    age: Age,
    isFirstTimeUser: boolean,
  ): Promise<Returns> => {
    if (PAYMENTS_OFFLINE && (!loggedInUserToken || amountOfCredits < 1)) {
      toast.error(
        'Payments are offline. Only users who previously purchased credits can continue. If you have credits, please log in to use them. Sorry for the inconvenience!',
      );
      setAuthModalActiveVariant('login');
      await waitSeconds(3);
      if (!loggedInUserToken) {
        setShowAuthModal(true);
      }
      return { status: 401 };
    }

    const functionName = 'undressImage';
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    const trackId = generateTrackId();
    setTrackIdOfImageToGenerate(trackId);

    const response = await fetch(fetchUrl, {
      method: 'POST',
      body: JSON.stringify({
        imageToUndressUrl,
        maskImageUrl,
        width,
        height,
        trackId,
        breasts,
        bodyType,
        age,
        token: loggedInUserToken,
        isFirstTimeUser,
      }),
      headers: headersForFetching,
      credentials: 'include',
    });

    const { status } = response;

    if (status === 200) {
      trackStartedUndressing();
      return { status };
    } else {
      alert('Something went wrong. Please try again.');
      return { status };
    }
  };
  return undressImage;
};

export default useUndressImage;
