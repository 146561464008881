import { useGeneralContext } from '../../../context/GeneralContextProvider';
import GeneratedImage from './GeneratedImage';
import ImageHistory from './ImageHistory';
import BasicContainerWithFadeIn from '../../../common/components/BasicContainerWithFadeIn';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const ResultsPage = () => {
  const { trackIdOfImageToGenerate } = useGeneralContext();
  const [trackId, setTrackId] = useState(trackIdOfImageToGenerate);
  const location = useLocation();

  // Parse the query parameters
  const query = new URLSearchParams(location.search);
  const isHistory = query.get('history') === 'true';

  useEffect(() => {
    if (isHistory) {
      setTrackId('');
      return;
    }

    setTrackId(trackIdOfImageToGenerate);
  }, [isHistory, trackIdOfImageToGenerate]);

  return (
    <BasicContainerWithFadeIn>
      <GeneratedImage trackId={trackId} />
      <ImageHistory />
    </BasicContainerWithFadeIn>
  );
};

export default ResultsPage;
