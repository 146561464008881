import React from 'react';
import styled from 'styled-components';

const Terms: React.FC = () => {
  return (
    <Container>
      <h1>Terms and Conditions</h1>

      <h2>Purpose of Service</h2>
      <p>
        Throughout these Terms and Conditions, "you", "your", and "user" refer
        to anyone, whether an individual or a legal entity, who accesses and/or
        uses the AINudes.io services. By using AINudes.io, you represent and
        warrant that you have the legal capacity to enter into a binding
        agreement.
      </p>

      <p>
        AINudes.io, provides a platform for creating realistic nude images of
        yourself or people who have given permission for you. The app uses AI to
        generate realistic nude images from clothed images. The app is for
        entertainment purposes only and should not be used for any illegal
        activities.
      </p>

      <h2>Privacy</h2>
      <p>
        AI Nudes Ltd. understands that users may access and use AI Nudes app.
        The Company takes precautions to protect users' personal data in
        accordance with applicable data protection laws. To exercise your data
        rights, AINudes.io can be contacted at support@ainudes.io with requests
        or concerns.
      </p>

      <p>
        AI Nudes Ltd. agrees never to sell or release to any third party the
        private information described below in "Private Information Stored,"
        except in accordance with any public sale, merger, or other acquisition
        of the Company or as required by law. Any subsequent parties who obtain
        said private information through public sale, merger, or acquisition
        shall likewise be bound by the provisions described in these Terms and
        Conditions, or if this agreement cannot be upheld, AI Nudes Ltd. will
        destroy such private information.
      </p>

      <h2>Private Information Stored</h2>
      <p>
        AINudes.io stores images only for a short period of time to generate the
        undressed image. We remove all images and will not go through any
        generations unless there is a reason to do so (i.e. a user reports an
        infringing image).
      </p>

      <h2>Intellectual Property Rights</h2>
      <p>
        By using AINudes.io, you agree to relinquish all worldwide intellectual
        property rights claims, regardless of legal jurisdiction or applicable
        intellectual property law, including any and all copyright claims, to
        the generated images, artifacts, or any other response content created
        during your use of the app.
      </p>

      <h2>Copyright</h2>
      <p>
        The concept of public domain may differ among legal jurisdictions. By
        using AINudes.io, you agree to abide by the terms of the CC0 1.0
        Universal Public Domain Dedication, available{' '}
        <a href="https://creativecommons.org/publicdomain/zero/1.0/">here</a>.
        All users, including "Affirmers" as defined in the Universal Public
        Domain Dedication, explicitly agree to the full terms of this
        dedication.
      </p>

      <h2>Ownership Rights</h2>
      <p>
        You retain ownership rights to any content you submit through
        AINudes.io.
      </p>

      <p>
        The license you grant the Company survives any termination, including
        for the reason of content removal, where the operation of the service
        can justify retention or if use of content is permitted to be retained
        before its removal, or where the content finds its way into our training
        data through accidental web-scraping or if the law requires otherwise.
        In any event, removal of content by the user, including opt-out users,
        would not require AI Nudes Ltd. to: (a) recall content that is being
        used by other users within any viewing functionality of the service; or
        (b) delete copies we reasonably would need to keep for legal purposes.
      </p>

      <h2>Waiver of Copyright and Intellectual Property Rights</h2>
      <p>
        By using AINudes.io, you waive all worldwide intellectual property
        rights claims, including any and all copyright claims, to the generated
        images, artifacts, or any other response content created during your use
        of the app. All users of AINudes.io acknowledge and accept the CC0 1.0
        Universal Public Domain Dedication, which includes a waiver of
        intellectual property rights with respect to any content.
      </p>

      <p>
        AINudes.io should not be used in any way that violates applicable
        national, federal, state, local, or international laws or regulations.
        By using AINudes.io, you agree to abide by all laws and regulations in
        your use of AINudes.io. It is especially important to not use AINudes.io
        to create images of underage people, or people who have not given
        permission to create images of them.
      </p>

      <p>
        AINudes.io allows you to edit images using the app's features. By
        generating and sharing content through AINudes.io, you represent and
        warrant that you own or have the necessary licenses, rights, consents,
        and permissions to use and authorize AI Nudes Ltd. to use all
        intellectual property and other proprietary rights in and to your
        generated content.
      </p>

      <h2>Indemnification</h2>
      <p>
        By using AINudes.io, you agree to indemnify and hold harmless AP
        Software Ltd. and its affiliates, officers, agents, employees, and
        licensors from and against any and all claims, damages, obligations,
        losses, liabilities, costs or debt, and expenses (including but not
        limited to reasonable attorneys' fees) arising from or in connection
        with any intellectual property infringement claims made against AP
        Software Ltd. with respect to your generated content or any other
        violation of these Terms and Conditions. This indemnification obligation
        shall survive termination of your use of AINudes.io.
      </p>

      <h2>User-Generated Content</h2>
      <p>
        By generating through AINudes.io, you also acknowledge and agree that
        you bear all responsibility for the content you upload and edit by using
        the app.
      </p>

      <h2>Choice of Law</h2>
      <p>
        By agreeing, you acknowledge and agree to these Terms and Conditions, as
        well as any other provisions incorporated herein by reference. Use of
        AINudes.io without acceptance of these Terms and Conditions constitutes
        unauthorized use, for which you disclaim any and all potential or actual
        right to relief against AI Nudes Ltd., regardless of AI Nudes's legal
        jurisdiction or your domicile, residence, or location.
      </p>
      <p>
        Creating anything illegal in the app is prohibited. You agree that any
        creations you make in the app will not be used for any illegal purposes.
        Maker of AINudes.io is not responsible for any illegal actions you take
        with the app. Attempting to use the app for illegal results in immediate
        termination of your account and ip ban.
      </p>

      <h2>Fees and Payments</h2>
      <h3>Billing</h3>
      <p>
        You may purchase a credit package to AINudes.io by paying a payment fee
        plus applicable taxes. Tax rates are calculated based on the information
        you provide and the applicable rate at the time of your monthly charge.
      </p>

      <h3>Price and Tax Changes</h3>
      <p>
        AINudes.io may from time to time make changes to payment fees, and will
        communicate any price changes to you in advance. Price changes will take
        effect at the start of the next subscription period following the date
        of the price change, and by continuing to use the AINudes.io Service
        after the price change takes effect, you will have accepted the new
        price. If you do not agree to a price change, you can reject the change
        by unsubscribing from the applicable subscription prior to the price
        change going into effect.
      </p>

      <p>
        AI Nudes holds the right to change the pricing from credit payment to
        other forms of payment, or to change the pricing of the subscription
      </p>

      <p>
        Tax rates are based on the rates applicable at the time of your monthly
        charge. These amounts can change over time with local tax requirements
        in your country, state, territory, county, or city. Any change in tax
        rate will be automatically applied based on the account information you
        provide.
      </p>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 90%;
`;

export default Terms;
