import { createContext, useContext, useState } from 'react';
import {
  Age,
  BodyType,
  Breasts,
  GenericSetState,
  ImageSize,
  UndressedImage,
} from '../types';
import { DEFAULT_SIZE } from '../constants';

const FETCH_USER_DATA_EVERY_N_SECONDS = 30;

interface IGeneralContext {
  uploadedImageUrl: string;
  setUploadedImageUrl: GenericSetState<string>;
  size: ImageSize;
  setSize: GenericSetState<ImageSize>;
  maskBase64Png: string;
  setMaskBase64Png: GenericSetState<string>;

  trackIdOfImageToGenerate: string;
  setTrackIdOfImageToGenerate: GenericSetState<string>;

  undressedImages: UndressedImage[];
  setUndressedImages: GenericSetState<UndressedImage[]>;

  breasts: Breasts;
  setBreasts: GenericSetState<Breasts>;
  bodyType: BodyType;
  setBodyType: GenericSetState<BodyType>;
  age: Age;
  setAge: GenericSetState<Age>;

  hasDrawn: boolean;
  setHasDrawn: GenericSetState<boolean>;

  amountOfCredits: number;
  setAmountOfCredits: GenericSetState<number>;
  loggedInUserToken: string;
  setLoggedInUserToken: GenericSetState<string>;
  showAuthModal: boolean;
  setShowAuthModal: GenericSetState<boolean>;
  authModalActiveVariant: 'login' | 'signup';
  setAuthModalActiveVariant: GenericSetState<'login' | 'signup'>;

  showBuyCreditsModal: boolean;
  setShowBuyCreditsModal: GenericSetState<boolean>;

  // helpers that's changed when user clicks buy credits
  fetchUserDataEveryNSeconds: number;
  setFetchUserDataEveryNSeconds: GenericSetState<number>;

  siteOffline: boolean;
  setSiteOffline: GenericSetState<boolean>;
}

const defaultState = {
  uploadedImageUrl: '',
  setUploadedImageUrl: undefined as unknown as GenericSetState<string>,
  size: DEFAULT_SIZE,
  setSize: undefined as unknown as GenericSetState<ImageSize>,
  maskBase64Png: '',
  setMaskBase64Png: undefined as unknown as GenericSetState<string>,
  trackIdOfImageToGenerate: '',
  setTrackIdOfImageToGenerate: undefined as unknown as GenericSetState<string>,

  undressedImages: [],
  setUndressedImages: undefined as unknown as GenericSetState<UndressedImage[]>,

  breasts: 'Small' as Breasts,
  setBreasts: undefined as unknown as GenericSetState<Breasts>,
  bodyType: 'Average' as BodyType,
  setBodyType: undefined as unknown as GenericSetState<BodyType>,
  age: '' as Age,
  setAge: undefined as unknown as GenericSetState<Age>,

  hasDrawn: false,
  setHasDrawn: undefined as unknown as GenericSetState<boolean>,

  amountOfCredits: 0,
  setAmountOfCredits: undefined as unknown as GenericSetState<number>,
  loggedInUserToken: '',
  setLoggedInUserToken: undefined as unknown as GenericSetState<string>,
  showAuthModal: false,
  setShowAuthModal: undefined as unknown as GenericSetState<boolean>,
  authModalActiveVariant: 'signup' as 'signup' | 'login',
  setAuthModalActiveVariant: undefined as unknown as GenericSetState<'login' | 'signup'>,

  showBuyCreditsModal: false,
  setShowBuyCreditsModal: undefined as unknown as GenericSetState<boolean>,

  fetchUserDataEveryNSeconds: FETCH_USER_DATA_EVERY_N_SECONDS,
  setFetchUserDataEveryNSeconds: undefined as unknown as GenericSetState<number>,

  siteOffline: true,
  setSiteOffline: undefined as unknown as GenericSetState<boolean>,
};

const GeneralContext = createContext<IGeneralContext>(defaultState);

export const useGeneralContext = () => useContext(GeneralContext);

// @ts-ignore children does actually exist, todo figure types?
const GeneralContextProvider = ({ children }) => {
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string>('');
  const [size, setSize] = useState<ImageSize>(DEFAULT_SIZE);
  const [maskBase64Png, setMaskBase64Png] = useState<string>('');
  const [trackIdOfImageToGenerate, setTrackIdOfImageToGenerate] =
    useState<string>('');

  const [undressedImages, setUndressedImages] = useState<UndressedImage[]>(
    [] as UndressedImage[],
  );

  const [breasts, setBreasts] = useState<Breasts>('Small' as Breasts);
  const [bodyType, setBodyType] = useState<BodyType>('Average' as BodyType);
  const [age, setAge] = useState<Age>('20 years old' as Age);

  const [hasDrawn, setHasDrawn] = useState<boolean>(false);

  const [amountOfCredits, setAmountOfCredits] = useState<number>(0);
  const [loggedInUserToken, setLoggedInUserToken] = useState<string>('');
  const [showAuthModal, setShowAuthModal] = useState<boolean>(false);
  const [authModalActiveVariant, setAuthModalActiveVariant] = useState<'login' | 'signup'>('signup');
  const [showBuyCreditsModal, setShowBuyCreditsModal] = useState<boolean>(false);

  const [fetchUserDataEveryNSeconds, setFetchUserDataEveryNSeconds] =
    useState<number>(FETCH_USER_DATA_EVERY_N_SECONDS);

  const [siteOffline, setSiteOffline] = useState<boolean>(true);

  return (
    <GeneralContext.Provider
      // TODO: Figure out if needed to wrap in useMemo
      // https://stackoverflow.com/questions/62230532/is-usememo-required-to-manage-state-via-the-context-api-in-reactjs
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        uploadedImageUrl,
        setUploadedImageUrl,
        size,
        setSize,
        maskBase64Png,
        setMaskBase64Png,

        trackIdOfImageToGenerate,
        setTrackIdOfImageToGenerate,

        undressedImages,
        setUndressedImages,

        age,
        setAge,
        breasts,
        setBreasts,
        bodyType,
        setBodyType,

        hasDrawn,
        setHasDrawn,

        amountOfCredits,
        setAmountOfCredits,
        loggedInUserToken,
        setLoggedInUserToken,
        showAuthModal,
        setShowAuthModal,
        authModalActiveVariant,
        setAuthModalActiveVariant,

        showBuyCreditsModal,
        setShowBuyCreditsModal,

        fetchUserDataEveryNSeconds,
        setFetchUserDataEveryNSeconds,
      
        siteOffline,
        setSiteOffline,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};

export default GeneralContextProvider;
