import styled from 'styled-components';
import { desktopMediaQuery } from '../../../styleHelpers';

export const StyledImage = styled.img`
  max-width: 95vw;
  max-height: 550px;
  ${desktopMediaQuery} {
    max-height: 750px;
  }
  width: auto;
  object-fit: contain;
  border-radius: 4px;
  border: 1px solid black;
`;
