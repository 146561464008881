import React from 'react';
import styled from 'styled-components';
import BaseModal, { CloseButton } from '../BaseModal';
import SignUpContent from './SignUpContent';
import LoginContent from './LoginContent';
import { ORANGE_LINK_COLOR } from '../../constants';
import { useGeneralContext } from '../../context/GeneralContextProvider';

interface I {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const AuthModal: React.FC<I> = ({ showModal, setShowModal }) => {
  const { authModalActiveVariant: variant, setAuthModalActiveVariant: setVariant} = useGeneralContext();

  const closeModal = () => setShowModal(false);

  return (
    <BaseModal showModal={showModal} onRequestClose={closeModal}>
      <CloseButton onClick={closeModal}>✖️</CloseButton>
      <ModalContent>
        {variant === 'login' ? <LoginContent closeModal={closeModal}/> : <SignUpContent closeModal={closeModal}/>}

        {variant === 'login' ? (
          <LinkOnBottom onClick={() => setVariant('signup')}>
            Don't have an account? Sign up
          </LinkOnBottom>
        ) : (
          <LinkOnBottom onClick={() => setVariant('login')}>
            Already have an account? Login
          </LinkOnBottom>
        )}
      </ModalContent>
    </BaseModal>
  );
};

const ModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  padding-top: 0;
`;

const LinkOnBottom = styled.div`
  margin-top: 16px;
  text-align: center;
  color: ${ORANGE_LINK_COLOR};
  cursor: pointer;
`;

export default AuthModal;
