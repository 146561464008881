import { useRef, useState } from 'react';
import styled from 'styled-components';
import {
  LOCAL_STORAGE_EXAMPLE_IMAGE_SEEN_KEY,
  MIN_DIMENSIONS,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
  SECONDARY_TEXT_COLOR,
} from '../../../constants';
import { MdOutlineFileUpload } from 'react-icons/md';
import useHandleReferenceImageUploading from './hooks/useHandleReferenceImageUploading';
import { useGeneralContext } from '../../../context/GeneralContextProvider';
import { DotLoader } from 'react-spinners';
import ExampleImageModal from '../../../modals/ExampleImageModal';
import { trackClickUploadImage } from '../../../analytics';
import { toast } from 'react-toastify';
import { waitSeconds } from '../../../helpers';
import { PAYMENTS_OFFLINE } from '../../LandingPage/BuyButton';

interface UploadReferenceImageProps {}

const UploadImageBox: React.FC<UploadReferenceImageProps> = () => {
  const { uploadedImageUrl, setUploadedImageUrl, setHasDrawn } =
    useGeneralContext();
  const { loggedInUserToken, setShowAuthModal, amountOfCredits, setAuthModalActiveVariant } =
    useGeneralContext();

  const [showExampleImageModal, setShowExampleImageModal] = useState(false);

  const { isUploading, handleImageUpload } =
    useHandleReferenceImageUploading(setUploadedImageUrl);

  const handleClick = async () => {
    if (PAYMENTS_OFFLINE && (!loggedInUserToken || amountOfCredits < 1)) {
      toast.error(
        'Payments are offline. Only users who previously purchased credits can continue. If you have credits, please log in to use them. Sorry for the inconvenience!',
      );
      setAuthModalActiveVariant('login');
      await waitSeconds(3);
      if (!loggedInUserToken) {
        setShowAuthModal(true);
      }
      return { status: 401 };
    }
    if (!localStorage.getItem(LOCAL_STORAGE_EXAMPLE_IMAGE_SEEN_KEY)) {
      setShowExampleImageModal(true);
      return;
    }
    trackClickUploadImage();
    handleStartImageUpload();
  };

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleStartImageUpload = () => fileInputRef.current?.click();

  const handleRemoveUploadedImage = () => {
    setUploadedImageUrl('');
    setHasDrawn(false);
  };

  return (
    <UploadContainer>
      <UploadBox onClick={handleClick}>
        {uploadedImageUrl ? (
          <ContainerWhenImageUploaded>
            <RemoveUploadedImage onClick={handleRemoveUploadedImage}>
              X
            </RemoveUploadedImage>
            <ImagePreview src={uploadedImageUrl} alt="Uploaded" />
          </ContainerWhenImageUploaded>
        ) : (
          <>
            <MdOutlineFileUpload size={24} />
            <UploadText>Click to Upload Image</UploadText>
            <UploadInstructions>
              Max file size: 5MB, Min dimensions: {MIN_DIMENSIONS}x
              {MIN_DIMENSIONS}
              <br />
              Supported formats: .png, .jpg, .jpeg, .webp
            </UploadInstructions>
            <HiddenInput
              type="file"
              accept=".png,.jpg,.jpeg,.webp"
              onChange={handleImageUpload}
              disabled={isUploading}
              ref={fileInputRef}
            />
          </>
        )}

        {isUploading && (
          <UploadingText>
            <DotLoader color={PRIMARY_COLOR} speedMultiplier={0.7} />
          </UploadingText>
        )}
      </UploadBox>

      <ExampleImageModal
        showModal={showExampleImageModal}
        setShowModal={setShowExampleImageModal}
        handleStartImageUpload={handleStartImageUpload}
      />
    </UploadContainer>
  );
};

export default UploadImageBox;

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;

  width: 100%;
`;

const ContainerWhenImageUploaded = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  width: 100%;
`;

const UploadBox = styled.div`
  position: relative; // this makes sure the X is in the right place
  background-color: none;
  color: ${PRIMARY_TEXT_COLOR};
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #555;
  transition: border-color 0.3s;

  width: 80%;
  max-width: 500px;

  &:hover {
    border-color: #777;
  }
`;

const UploadText = styled.p`
  margin-top: 10px;
  font-size: 16px;
`;

const UploadInstructions = styled.p`
  margin-top: 5px;
  font-size: 12px;
  color: ${SECONDARY_TEXT_COLOR};
`;

const HiddenInput = styled.input`
  display: none;
`;

const UploadingText = styled.p`
  color: ${PRIMARY_TEXT_COLOR};
  margin-top: 10px;
`;

const ImagePreview = styled.img`
  max-width: 200px;
  max-height: 200px;
  border-radius: 5px;

  margin-bottom: 10px;
`;

// simple "X" button to remove the uploaded image
const RemoveUploadedImage = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  border: none;
  background-color: transparent;
  color: ${PRIMARY_TEXT_COLOR};
  cursor: pointer;
  font-size: 20px;
  transition: color 0.3s;

  &:hover {
    color: #777;
  }
`;
