import styled from 'styled-components';
import PrimaryButton from '../../../common/components/PrimaryButton';
import useUndressImage from './hooks/useUndressImage';
import { useGeneralContext } from '../../../context/GeneralContextProvider';
import useUploadImage from './hooks/useUploadImage';
import { useNavigate } from 'react-router-dom';
import { RESULTS_PATH } from '../../../pathNames';
import { FaWandMagicSparkles } from 'react-icons/fa6';
import { scrollToTopSmoothly } from '../../../helpers';
import { useState } from 'react';
import { BounceLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import FirstTimeUserStorage from '../../../common/storages/firstTimeUserStorage';
import { trackUsedCredits } from '../../../analytics';

const StartUndressingButton = () => {
  const navigate = useNavigate();
  const uploadImage = useUploadImage();
  const undressImage = useUndressImage();
  const {
    loggedInUserToken,
    setShowBuyCreditsModal,
    maskBase64Png,
    uploadedImageUrl,
    size,
    breasts,
    bodyType,
    age,
    amountOfCredits,
    setAmountOfCredits,
  } = useGeneralContext();

  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    const firstTimeUserStorage = new FirstTimeUserStorage();
    const isFirstTimeUser =
      !loggedInUserToken && firstTimeUserStorage.isFirstTimeUser();

    if (!isFirstTimeUser && amountOfCredits < 1) {
      toast.error('No credits to undress.');
      setShowBuyCreditsModal(true);
      return;
    }

    if (loading) return;
    setLoading(true);

    if (!isFirstTimeUser) {
      trackUsedCredits();
      setAmountOfCredits(amountOfCredits - 1);
    }

    const maskResult = await uploadImage(maskBase64Png);
    if (
      !maskResult ||
      maskResult.status !== 200 ||
      !maskResult.imageUrl ||
      !uploadedImageUrl
    ) {
      toast.error('Something went wrong. Please try again.');
      return;
    }

    const r = await undressImage(
      uploadedImageUrl,
      maskResult.imageUrl,
      size.width,
      size.height,
      breasts,
      bodyType,
      age,
      isFirstTimeUser,
    );

    if (!r || r.status !== 200) {
      toast.error('Something went wrong. Please try again.');
      return;
    }

    firstTimeUserStorage.setNotFirstTimeUser();

    setLoading(false);
    scrollToTopSmoothly();
    navigate(RESULTS_PATH);
  };
  return (
    <Container>
      <PrimaryButton onClick={onClick}>
        {loading ? (
          <BounceLoader color={'white'} size={24} />
        ) : (
          <>
            <FaWandMagicSparkles /> Undress
          </>
        )}
      </PrimaryButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 80vw;
  max-width: 400px;
`;

export default StartUndressingButton;
