import React from 'react';
import styled from 'styled-components';
import BaseModal from './BaseModal';
import PrimaryButton from '../common/components/PrimaryButton';
import { LOCAL_STORAGE_ACCEPTED_TERMS_KEY, ORANGE_LINK_COLOR } from '../constants';
import { PRIVACY_POLICY_URL, USE_POLICY_URL, TERMS_URL } from '../pathNames';

interface I {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const AcceptTermsModal: React.FC<I> = ({ showModal, setShowModal }) => {
  const handleAccept = () => {
    localStorage.setItem(LOCAL_STORAGE_ACCEPTED_TERMS_KEY, 'true');
    setShowModal(false);
  };


  return (
    <BaseModal showModal={showModal} onRequestClose={() => {}}>
      <ModalContent>
        <h2>Agree to Terms to Proceed</h2>
        <p>🔞 You must be 18 or older</p>
        <p>
          ❌ Do not use photos of individuals without their explicit permission,
          or if they are under 18 years of age
        </p>
        <p>
          🛡️ You bear full responsibility for the images you generate on this
          platform
        </p>

        <p>
          ✅ By accepting, you agree to the rules above, and to our{' '}
          <a href={PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">Privacy Policy</a>,{' '}
          <a href={USE_POLICY_URL} target="_blank" rel="noopener noreferrer">AUP</a>, and{' '}
          <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">Terms of Service</a>
        </p>

        <PrimaryButton onClick={handleAccept}>Accept</PrimaryButton>
      </ModalContent>
    </BaseModal>
  );
};
const ModalContent = styled.div`
  padding: 16px;
  padding-top: 0;

  a {
    color: ${ORANGE_LINK_COLOR};
    text-decoration: none;
  }
`;

export default AcceptTermsModal;
