import React from 'react';
import styled from 'styled-components';
import { useGeneralContext } from '../../context/GeneralContextProvider';
import { PRIMARY_TEXT_COLOR } from '../../constants';
import { desktopMediaQuery } from '../../styleHelpers';
import { CreditDiamondIcon } from './CreditDiamondIcon';

const CreditIndicatorInTopBar: React.FC = () => {
  const { amountOfCredits } = useGeneralContext();

  return (
    <CreditsDisplay>
      <CreditDiamondIcon />
      <Credits>{amountOfCredits}</Credits>
    </CreditsDisplay>
  );
};

const CreditsDisplay = styled.div`
  display: flex;
  align-items: center;

  margin-right: 8px;
  margin-top: 1px;

  ${desktopMediaQuery} {
    font-size: 22px;
  }
  font-weight: 400;
`;

const Credits = styled.span`
  margin-left: 4px;
  font-size: 16px;
  ${desktopMediaQuery} {
    font-size: 18px;
  }

  color: ${PRIMARY_TEXT_COLOR};
`;

export default CreditIndicatorInTopBar;
