import { getBaseUrlForFetching } from '../../../../App';
import { trackUploadedImage } from '../../../../analytics';
import { headersForFetching } from '../../../../helpers';

interface Returns {
  status: number;
  imageUrl?: string;
}

/**
 *  Returns a function that uploads a base64 encoded image to the server.
 */
const useUploadImage = () => {
  const uploadImage = async (base64Png: string): Promise<Returns> => {
    const functionName = 'uploadImage';
    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;

    const transformedBase64 = base64Png.replace(
      /^data:image\/(png|jpeg|jpg|webp);base64,/,
      '',
    );
    const response = await fetch(fetchUrl, {
      method: 'POST',
      body: JSON.stringify({ base64_image: transformedBase64 }),
      headers: headersForFetching,
    });

    const { status } = response;
    if (status === 200) {
      const { body } = await response.json();
      trackUploadedImage();
      return { status, imageUrl: body };
    } else {
      return { status };
    }
  };
  return uploadImage;
};

export default useUploadImage;
