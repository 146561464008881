import styled from "styled-components";
import { BORDER_COLOR, PRIMARY_COLOR, PRIMARY_TEXT_COLOR } from "../../../constants";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../common/components/PrimaryButton";

const ContentPolicyPage = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Container>
      <TitleText>Content policy</TitleText>
      <Text>
        At OnlyFakes, we are committed to maintaining a safe and inclusive
        environment for all of our users. We believe in transparency,
        accountability, and swift resolution when it comes to addressing any
        issues that may arise on our platform. In alignment with our commitment
        to uphold the highest standards, we have established a comprehensive
        Complaints Policy, outlined below.
      </Text>
      <Text>
        <b>Reporting of Content:</b>
        <br />
        We encourage our users to report any content that they believe may be
        illegal or violates our Standards. To file a complaint, please report
        the image on the feed or contact us at support@onlyfakes.app. Please
        provide as much detail as possible to assist us in our investigation.
      </Text>
      <Text>
        <b>Complaint Review and Resolution:</b>
        <br />
        Upon receiving a complaint, our dedicated review team will acknowledge
        receipt of your complaint within 7 days. All reported complaints will be
        thoroughly reviewed and resolved within 7 business days.
      </Text>
      <Text>
        Our review process involves:
        <br />
        1. Initial Assessment: We will assess the complaint to understand its
        nature and determine the necessary steps for resolution.
        <br />
        2. Investigation: Our team will conduct a thorough investigation,
        gathering all relevant information and context regarding the reported
        content.
        <br />
        3. Decision-Making: Based on the evidence gathered, our team will make
        an informed decision on how to proceed.
        <br />
        4. Resolution: We will take appropriate action, which may include
        removal of the content, issuing a warning, or other measures deemed
        necessary.
      </Text>

      <Text>
        <b>Appeals Process:</b>
        <br />
        If you are dissatisfied with the outcome of your complaint, you have the
        right to appeal the decision. To initiate an appeal, please send an
        email to support@onlyfakes.app within 7 days of receiving our decision.
        Please provide a clear and concise explanation of why you believe the
        decision should be reconsidered. Our appeals team will review your
        submission and provide a final decision within 7 days.
      </Text>

      <Text>
        <b>Potential Outcomes:</b>
        <br />
        The potential outcomes of our investigations and reviews may vary
        depending on the nature of the complaint and the evidence gathered.
        Potential outcomes may include, but are not limited to: Removal of the
        reported content Issuing a warning to the responsible user Temporary or
        permanent suspension of the responsible user’s account Reporting the
        issue to law enforcement (in cases of illegal content)
      </Text>

      <Text>
        <b>Contact Information:</b>
        <br />
        For any questions or further clarification regarding our Complaints
        Policy, please feel free to contact us at support@onlyfakes.app.
      </Text>
      <AcceptButton onClick={handleBack}>OK</AcceptButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 64px;

  border: 1px solid ${BORDER_COLOR};

  max-width: 400px;

  //make it be in the very middle of the page
  position: absolute;
  left: 50%;
  transform: translate(-50%, -0%);
`;

const Text = styled.div`
  font-size: 16px;
  color: ${PRIMARY_TEXT_COLOR};
  // justify
  text-align: justify;
  margin-bottom: 16px;
`;

const TitleText = styled(Text)`
  font-size: 24px;
`;

const AcceptButton = styled(PrimaryButton)`
  border-color: ${PRIMARY_COLOR};
`;

export default ContentPolicyPage;
