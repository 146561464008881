import React from 'react';
import styled from 'styled-components';
import BaseModal from './BaseModal';
import PrimaryButton from '../common/components/PrimaryButton';
import { LOCAL_STORAGE_EXAMPLE_IMAGE_SEEN_KEY } from '../constants';
import { fadeIn } from '../common/animations';

interface I {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  handleStartImageUpload: () => void;
}

const ExampleImageModal: React.FC<I> = ({
  showModal,
  setShowModal,
  handleStartImageUpload,
}) => {
  const handleClose = () => {
    localStorage.setItem(LOCAL_STORAGE_EXAMPLE_IMAGE_SEEN_KEY, 'true');
    setShowModal(false);
    handleStartImageUpload();
  };

  return (
    <BaseModal showModal={showModal} onRequestClose={handleClose}>
      <ModalContent>
        <ExampleImageToUpload src="example1-dressed.jpeg" alt="Example Image" />
        Upload an image like this for good results
        <br/>
        -  She's showing a lot of skin
        <br />
        -  The image is closely cropped
        <br />
        - She standing in the center
        <PrimaryButton onClick={handleClose}>OK</PrimaryButton>
      </ModalContent>
    </BaseModal>
  );
};
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  `;

const ExampleImageToUpload = styled.img`
  width: 100%;
  height: auto;
  max-width: 70vw;
  max-height: 70vh;

  animation: ${fadeIn} 1s ease-in;
`;

export default ExampleImageModal;
