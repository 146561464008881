// Extend the Window interface to include Plausible's `plausible` function
declare global {
  interface Window {
    plausible: (
      eventName: string,
      options?: { props?: Record<string, any>; callback?: () => void },
    ) => void;
  }
}

/**
 * Tracks events using Plausible analytics.
 * @param eventName The name of the event to track.
 */
const trackEvent = (eventName: string) => {
  if (window.plausible) {
    window.plausible(eventName);
  }
};

export const trackClickUploadImage = () => {
  trackEvent('upload-image-clicked');
};

export const trackUploadedImage = () => {
  trackEvent('uploaded-image');
};

export const trackStartedUndressing = () => {
  trackEvent('started-undressing');
};

export const trackAskedToSignup = () => {
  trackEvent('asked-to-signup');
};

export const trackSignedUp = () => {
  trackEvent('signed-up');
};

export const trackOpenBuyCreditsModal = () => {
  trackEvent('buy-modal-opened');
};

export const trackClickBuy = () => {
  trackEvent('buy-credits-clicked');
};

export const trackUsedCredits = () => {
  trackEvent('used-credits');
};

export {};
