import { getBaseUrlForFetching } from '../../../../App';
import { headersForFetching, waitSeconds } from '../../../../helpers';

const WAIT_TIME = 3;
const AMOUNT_OF_RETRIES = 20;

interface ImageData {
  trackId: string;
  imageUrl?: string;
  blurredImageInBase64?: string;
  showBlurredVersion?: boolean;
}

const useGetImageDataWithTrackId = () => {
  const getImageDataFromTrackId = async (trackId: string): Promise<ImageData | null | 'cancelled'> => {
    const functionName = 'getImageData';

    const baseUrl = getBaseUrlForFetching();
    const fetchUrl = `${baseUrl}/${functionName}`;
    let retries = 0;

    while (true) {
      try {
        const response = await fetch(fetchUrl, {
          method: 'POST',
          body: JSON.stringify({ trackId }),
          headers: headersForFetching,
        });

        if (response.status === 429) {
          retries++;
          if (retries > AMOUNT_OF_RETRIES) {
            return null;
          }
          await waitSeconds(WAIT_TIME);
          continue;
        }

        if (response.status === 200) {
          const data = await response.json();
          // if url is "generating", wait a bit and try again
          if (data.imageUrl === 'generating') {
            retries++;

            if (retries > AMOUNT_OF_RETRIES) {
              return null;
            }

            await waitSeconds(WAIT_TIME);
            continue;
          }
          return data;
        }

        if (response.status === 404) {
          retries++;
          await waitSeconds(WAIT_TIME);
          if (retries > AMOUNT_OF_RETRIES) {
            return 'cancelled';
          }
          continue;
        }

        retries++;
        if (retries > AMOUNT_OF_RETRIES) {
          return null;
        }
        await waitSeconds(WAIT_TIME);
      } catch (error) {
        retries++;
        if (retries > AMOUNT_OF_RETRIES) {
          return null;
        }
        await waitSeconds(WAIT_TIME);
        continue;
      }
    }
  };
  return getImageDataFromTrackId;
};

export default useGetImageDataWithTrackId;
