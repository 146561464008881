import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import { BASE_URL, PREVIEW_URL_FOR_FUNCTIONS } from './constants';
import GeneralContextProvider from './context/GeneralContextProvider';
import Footer from './pages/MainApp/MainAppCommon/Footer';
import useHandleGatherReferralId from './common/hooks/useHandleGatherReferralId';

export const isLocalhost = window.location.href.includes('localhost');
export const isPreviewing = window.location.href.includes('deploy-preview');
export const isInNetlify = false;

export const getBaseUrlForFetching = () => {
  if (isLocalhost) {
    return 'http://localhost:8080';
  }
  if (isPreviewing) {
    return PREVIEW_URL_FOR_FUNCTIONS;
  }
  if (isInNetlify) {
    return 'https://ainudes.netlify.app/.netlify/functions';
  }
  return `${BASE_URL}/.netlify/functions`;
};

/**
 * This is called from index.tsx and is the main entry point of the app
 */
function App() {
  useHandleGatherReferralId();
  return (
    <Router>
      <GeneralContextProvider>
        <Routes />
        <Footer />
      </GeneralContextProvider>
    </Router>
  );
}

export default App;
