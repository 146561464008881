import { useState } from 'react';

const usePasswordVisibility = (passwordFieldId: string) => {
  const [eyeIcon, setEyeIcon] = useState<'open' | 'closed'>('closed');

  const handleClickOnEye = () => {
    const passwordField = document.getElementById(passwordFieldId) as HTMLInputElement;
    if (passwordField.type === 'password') {
      passwordField.type = 'text';
    } else {
      passwordField.type = 'password';
    }
    setEyeIcon(eyeIcon === 'closed' ? 'open' : 'closed');
  };

  return { eyeIcon, handleClickOnEye };
};

export default usePasswordVisibility;