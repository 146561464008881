import { useEffect } from "react";
import ReferralStorage from "../storages/referralStorage";

const getReferralIdFromURL = (): string | null => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get("r-id");
};

/**
 * This hook checks if there is a referral id in the url and saves it to local storage
 *
 * The refarral id is used to give the user who referred the new user credits
 *
 * See creditService.ts for more info
 */
export default function useHandleGatherReferralId() {
  useEffect(() => {
    const referralId = getReferralIdFromURL();
    if (referralId) {
      const referralIdStorage = new ReferralStorage();
      referralIdStorage.setReferralId(referralId);
    }
  }, []);
}
