import { useEffect } from 'react';

/**
 * useDrawMask is a custom hook that takes a canvas reference and a base64 string of a PNG image.
 * It draws the image onto the canvas whenever the base64 string changes.
 * This is useful for restoring the canvas state when navigating away and coming back.
 *
 * @param {React.RefObject<HTMLCanvasElement>} canvasRef - The reference to the canvas element.
 * @param {string} maskBase64Png - The base64 string representation of the PNG image.
 * @returns {void}
 */
const useDrawMask = (
  canvasRef: React.RefObject<HTMLCanvasElement>,
  maskBase64Png: string | undefined,
) => {
  const drawMask = (base64String: string) => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');

    if (!canvas || !context) {
      return;
    }

    const image = new Image();
    image.onload = () => {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0, canvas.width, canvas.height);
    };
    image.src = base64String;
  };

  useEffect(() => {
    if (maskBase64Png) {
      drawMask(maskBase64Png);
    }
  }, [maskBase64Png]);
};

export default useDrawMask;
