import React, { useEffect } from 'react';
import styled from 'styled-components';
import BaseModal, { CloseButton } from './BaseModal';
import CreditIndicatorInTopBar from '../common/components/CreditIndicator';
import PaymentComponent from '../common/components/PaymentComponent';
import { MAX_WIDTH } from '../constants';
import { trackOpenBuyCreditsModal } from '../analytics';

interface I {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const BuyCreditsModal: React.FC<I> = ({ showModal, setShowModal }) => {
  const closeModal = () => setShowModal(false);

  useEffect(() => {
    if (showModal) {
      trackOpenBuyCreditsModal();
    }
  }, [showModal]);

  return (
    <BaseModal
      showModal={showModal}
      onRequestClose={closeModal}
      showWiderVersion
    >
      <ModalContent>
        <CloseButton onClick={closeModal}>✖️</CloseButton>
        <TopPartContainer>
          <CurrentCreditsContainer>
            Current credits: <CreditIndicatorInTopBar />
          </CurrentCreditsContainer>
          <InCaseOfProblemsText>
            For payment issues or refunds, please email support@ainudes.io
          </InCaseOfProblemsText>
        </TopPartContainer>

        <PaymentComponent />
      </ModalContent>
    </BaseModal>
  );
};

const TopPartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const InCaseOfProblemsText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;
`;

const CurrentCreditsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${MAX_WIDTH}px) {
    align-items: center;
  }
  justify-items: center;
  padding-right: 8px;
  padding-top: 0;
  font-size: 22px;
  overflow-y: auto;

  max-height: 88vh;
  width: 99%;
  max-width: 85vw;
`;

export default BuyCreditsModal;
