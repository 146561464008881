import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: #007bff;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const FeaturedOn: React.FC = () => {
  return (
    <Container>
      <a href="https://www.ainsfwtools.com/tool/clothes-remover-ai-porn">
        Featured on AI NSFW Tools
      </a>
    </Container>
  );
};

export default FeaturedOn;
