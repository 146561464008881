import React from 'react';
import { MdOutlineLogin, MdOutlineLogout } from 'react-icons/md';
import { toast } from 'react-toastify';

import { useGeneralContext } from '../../../../context/GeneralContextProvider';
import UserTokenStorage from '../../../../common/storages/userTokenStorage';
import { OptionContainer, ButtonWithIcon } from './TopMenuCommon';

interface P {
  closePopup: () => void;
}

export const LogoutOption: React.FC<P> = ({ closePopup }) => {
  const { setLoggedInUserToken } = useGeneralContext();
  const userTokenStorage = new UserTokenStorage();
  const onLogout = () => {
    setLoggedInUserToken('');
    userTokenStorage.removeToken();
    toast.success('Logged out successfully');
    closePopup();
  };
  return (
    <OptionContainer onClick={onLogout}>
      <ButtonWithIcon>
        <MdOutlineLogout size={18} />
        Logout
      </ButtonWithIcon>
    </OptionContainer>
  );
};

export const LoginOption: React.FC<P> = ({ closePopup }) => {
  const { setShowAuthModal, setAuthModalActiveVariant } = useGeneralContext();
  const handleClick = () => {
    closePopup();
    setAuthModalActiveVariant('login');
    setShowAuthModal(true);
  };
  return (
    <OptionContainer onClick={handleClick}>
      <ButtonWithIcon>
        <MdOutlineLogin />
        Login / Signup
      </ButtonWithIcon>
    </OptionContainer>
  );
};

export default {};
