import styled from 'styled-components';
import {
  PRIMARY_TEXT_COLOR,
  LIGHTER_GREY_FOR_HOVER_EFFECT,
} from '../../../../constants';

export const OptionContainer = styled.div`
  padding: 6px;
  color: ${PRIMARY_TEXT_COLOR};
  cursor: pointer;

  transition: background-color 0.2s;
  &:hover {
    // pointer mouse:
    cursor: pointer;
    background-color: ${LIGHTER_GREY_FOR_HOVER_EFFECT};
  }
  display: flex;

  button {
    cursor: pointer;
  }
`;

export const ButtonWithIcon = styled.button`
  background-color: transparent;
  align-items: center;
  display: flex;
  gap: 8px;
  font-size: 16px;

  border-radius: 8px;

  border: none;
`;
