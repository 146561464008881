import styled from 'styled-components';
import { PRIMARY_COLOR, PRIMARY_TEXT_COLOR } from '../../../constants';

export const InstructionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;
  margin-top: 32px;
  margin-bottom: 32px;
`;

export const InstructionsTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  background: -webkit-linear-gradient(
    45deg,
    ${PRIMARY_COLOR},
    ${PRIMARY_TEXT_COLOR}
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  margin-bottom: 16px;
`;

export const InstructionsText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 16px;
  color: ${PRIMARY_TEXT_COLOR};
  p {
    display: flex;
    align-items: center;
    margin: 0;
    gap: 12px;
  }

  max-width: 400px;
`;
