import React from 'react';
import styled from 'styled-components';

const PrivacyPolicy = () => {
  return (
    <Container>
      <h1>Privacy Policy</h1>

      <p>Updated: 25 April 2024</p>

      <h2>Personal Data</h2>
      <p>
        AINudes.io will NOT share your information with business partners for
        products, services, or promotions. We don't retain your personal data.
        Usage data is kept for internal analysis purposes, and for improving the
        functionality or security of our service.
      </p>

      <h2>Data Transfer and Disclosure</h2>
      <p>
        Your data may be transferred and maintained on computers outside your
        jurisdiction. By submitting your information, you agree to this
        transfer. We ensure the security of your data during transfers. Your
        personal data may be disclosed in business transactions (e.g., mergers,
        acquisitions), to law enforcement or public authorities, or for other
        legal requirements (e.g., protecting user safety, preventing
        wrongdoing).
      </p>

      <h2>Data Security</h2>
      <p>
        We understand the significance of securing your personal data, but it's
        imperative to bear in mind that there is no foolproof method of
        transmission over the internet or electronic storage. Despite our
        efforts to utilize commercially reasonable methods to protect your
        personal data, we cannot assure its complete security. Our service is
        not intended for children under 18, and we remove their data if
        discovered.
      </p>

      <h2>Third-Party Websites and Privacy Policy Updates</h2>
      <p>
        We are not responsible for third-party websites linked from our service.
        Review their privacy policies for more information. This privacy policy
        may be updated, and you will be notified of changes via email or a
        notice on our service. Review the privacy policy periodically to stay
        informed.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at support@ainudes.io
      </p>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 80%;

  margin-left: 5vw;
`;
export default PrivacyPolicy;
