import styled from 'styled-components';

import InpaintPart from './InpaintPart/InpaintPart';
import SettingsPart from './SettingsPart';
import StartUndressingButton from './StartUndressingButton';
import UploadPart from './UploadPart';
import { useGeneralContext } from '../../../context/GeneralContextProvider';
import BasicContainerWithFadeIn from '../../../common/components/BasicContainerWithFadeIn';

const Undress = () => {
  const { maskBase64Png } = useGeneralContext();

  // TODO: this can be removed probably
  const showTheRest = !!maskBase64Png;
  const shouldPutEmptySpace = !showTheRest
  return (
    <BasicContainerWithFadeIn>
      <UploadPart />
      <InpaintPart />
      {showTheRest && (
        <>
          <SettingsPart />
          <StartUndressingButton />
        </>
      )}

      {
        shouldPutEmptySpace && <EmptySpace />
      }
    </BasicContainerWithFadeIn>
  );
};

const EmptySpace = styled.div`
  height: 300px;
`;

export default Undress;
