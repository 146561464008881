import React from 'react';
import {
  CardContainer,
  CardTitle,
  CardText,
} from '../../common/components/cardParts';

const HowItWorks: React.FC = () => {
  return (
    <CardContainer>
      <CardTitle>How does it work?</CardTitle>
      <CardText>
        The app leverages cutting-edge AI technology - similar to DALL-E in ChatGPT - utilizing a neural network
        trained on billions of images to generate highly realistic human bodies for incredibly realistic deepfakes.
      </CardText>
    </CardContainer>
  );
};

export default HowItWorks;
