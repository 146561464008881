import React, { useRef, useEffect, useState, CSSProperties } from 'react';
import styled from 'styled-components';
import { useGeneralContext } from '../../../../context/GeneralContextProvider';
import { BORDER_COLOR } from '../../../../constants';
import InpaintingOptions from './InpaintingOptions';
import useDrawMaskWithPreviousMask from '../hooks/useDrawMaskWithPreviousMask';
import { waitSeconds } from '../../../../helpers';
import { desktopMediaQuery, getIsOnDesktop } from '../../../../styleHelpers';

const DEFAULT_BRUSH_SIZE = 80;

const CLEARED_CANVAS_STYLE: CSSProperties = {
  display: 'none',
};

const DrawingCanvas: React.FC = () => {
  const {
    uploadedImageUrl,
    setSize,
    maskBase64Png,
    setMaskBase64Png,

    hasDrawn,
    setHasDrawn,
  } = useGeneralContext();

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const zoomCanvasRef = useRef<HTMLCanvasElement>(null);

  const [zoomCanvasStyle, setZoomCanvasStyle] =
    useState<CSSProperties>(CLEARED_CANVAS_STYLE);

  const [brushSize, setBrushSize] = useState<number>(DEFAULT_BRUSH_SIZE);

  useDrawMaskWithPreviousMask(canvasRef, maskBase64Png);

  const handleClearCanvas = () => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    clearCanvas(canvas);
    setHasDrawn(false);
  };

  //() => undo(canvas.getContext('2d')!)
  const handleUndo = () => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');

    if (!canvas || !context) {
      return;
    }
    undo(context);
  };

  type CanvasState = ImageData;

  const historyRef = useRef<CanvasState[]>([]);
  const undoIndexRef = useRef<number>(-1);

  const undo = (context: CanvasRenderingContext2D) => {
    if (undoIndexRef.current === 2) {
      clearCanvas(canvasRef.current!);
      return;
    }
    if (undoIndexRef.current <= 0) {
      return;
    }

    undoIndexRef.current--;
    // Clear the canvas before restoring previous state
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);

    const imageData = historyRef.current[undoIndexRef.current];
    context.putImageData(imageData, 0, 0);
  };

  const saveState = (context: CanvasRenderingContext2D) => {
    const imageData = context.getImageData(
      0,
      0,
      context.canvas.width,
      context.canvas.height,
    );
    // Remove states after current index if undo was used
    historyRef.current = historyRef.current.slice(0, undoIndexRef.current + 1);
    historyRef.current.push(imageData);
    undoIndexRef.current++;
  };

  // this is for to the brush size is updated when the user changes it
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');

    if (!canvas || !context) {
      return;
    }

    context.lineWidth = brushSize;
  }, [brushSize, canvasRef]);

  // This updates the maskBase64Png every second
  useEffect(() => {
    const interval = setInterval(() => {
      const canvas = canvasRef.current;
      if (!canvas) {
        return;
      }
      const base64Png = getBase64Png(canvas);
      setMaskBase64Png(base64Png);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const getFullscreenMouseCoordinates = (event: MouseEvent | TouchEvent) => {
    let x = 0;
    let y = 0;

    if (event instanceof MouseEvent) {
      x = event.clientX;
      y = event.clientY;
    } else if (event instanceof TouchEvent && event.touches.length > 0) {
      x = event.touches[0].clientX;
      y = event.touches[0].clientY;
    }

    // Adjust for scrolling if your element might be affected
    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;

    // Final coordinates considering scrolling
    return { fullScreenX: x + scrollX, fullScreenY: y + scrollY };
  };

  const isOnDesktop = getIsOnDesktop();

  useEffect(() => {
    const canvas = canvasRef.current;
    const image = imageRef.current;
    const zoomCanvas = zoomCanvasRef.current;

    const context = canvas?.getContext('2d');
    const zoomContext = zoomCanvas?.getContext('2d');

    if (!canvas || !context || !image || !zoomCanvas || !zoomContext) {
      return;
    }

    saveState(context);

    let isDrawing = false;
    let lastX = 0;
    let lastY = 0;

    const startDrawing = (event: MouseEvent | TouchEvent) => {
      setHasDrawn(true);
      isDrawing = true;
      const { offsetX, offsetY } = getCoordinates(event);
      lastX = offsetX;
      lastY = offsetY;
    };
    const stopDrawing = () => {
      isDrawing = false;
      setZoomCanvasStyle({
        opacity: 0,
      });
      saveState(context);
    };

    const draw = (x: number, y: number) => {
      if (!isDrawing) {
        return;
      }

      context.strokeStyle = '#FFFFFF';
      if (context.lineWidth < 10) {
        context.lineWidth = DEFAULT_BRUSH_SIZE;
      }
      context.lineCap = 'round';

      context.beginPath();
      context.moveTo(lastX, lastY);
      context.lineTo(x, y);
      context.stroke();

      lastX = x;
      lastY = y;
    };

    const drawOnMove = (event: MouseEvent | TouchEvent) => {
      event.preventDefault();
      const { offsetX, offsetY } = getCoordinates(event);

      const updateZoom = (x: number, y: number) => {
        if (!isDrawing || isOnDesktop) {
          setZoomCanvasStyle(CLEARED_CANVAS_STYLE);
          return;
        }
        const scale = 2; // Zoom scale
        const zoomSize = 500; // Size of the zoomed area

        // Clear the zoom canvas
        zoomContext.clearRect(0, 0, zoomCanvas.width, zoomCanvas.height);

        // First draw the image part that corresponds to the current cursor position
        if (image.complete && image.naturalWidth > 0) {
          zoomContext.globalAlpha = 1.0;
          zoomContext.drawImage(
            image,
            x - zoomSize / (2 * scale),
            y - zoomSize / (2 * scale),
            zoomSize / scale,
            zoomSize / scale,
            0,
            0,
            zoomCanvas.width,
            zoomCanvas.height,
          );
        }
        // Then draw the corresponding part of the drawing canvas
        zoomContext.globalAlpha = 0.2;
        zoomContext.drawImage(
          canvas,
          x - zoomSize / (2 * scale),
          y - zoomSize / (2 * scale),
          zoomSize / scale,
          zoomSize / scale,
          0,
          0,
          zoomCanvas.width,
          zoomCanvas.height,
        );

        const { fullScreenX, fullScreenY } =
          getFullscreenMouseCoordinates(event);
        if (!fullScreenX || !fullScreenY) return;
        setZoomCanvasStyle({
          display: 'flex',
          position: 'absolute',
          height: '175px',
          width: '175px',
          top: `${fullScreenY - 1170}px`,
          left: `${fullScreenX - 200}px`,
        });
      };

      updateZoom(offsetX, offsetY);
      draw(offsetX, offsetY);
    };

    const getCoordinates = (event: MouseEvent | TouchEvent) => {
      const canvasRect = canvas.getBoundingClientRect();
      const scaleX = canvas.width / canvasRect.width;
      const scaleY = canvas.height / canvasRect.height;

      if (event instanceof MouseEvent) {
        return {
          offsetX: (event.clientX - canvasRect.left) * scaleX,
          offsetY: (event.clientY - canvasRect.top) * scaleY,
        };
      }
      return {
        offsetX: (event.touches[0].clientX - canvasRect.left) * scaleX,
        offsetY: (event.touches[0].clientY - canvasRect.top) * scaleY,
      };
    };

    canvas.addEventListener('mousedown', startDrawing);
    canvas.addEventListener('touchstart', startDrawing);
    canvas.addEventListener('mouseup', stopDrawing);
    canvas.addEventListener('touchend', stopDrawing);
    canvas.addEventListener('mousemove', drawOnMove);
    canvas.addEventListener('touchmove', drawOnMove);

    return () => {
      canvas.removeEventListener('mousedown', startDrawing);
      canvas.removeEventListener('touchstart', startDrawing);
      canvas.removeEventListener('mouseup', stopDrawing);
      canvas.removeEventListener('touchend', stopDrawing);
      canvas.removeEventListener('mousemove', drawOnMove);
      canvas.removeEventListener('touchmove', drawOnMove);
    };
  }, [canvasRef, imageRef, zoomCanvasRef]);

  const clearCanvas = (canvas: HTMLCanvasElement) => {
    const context = canvas.getContext('2d');

    if (!context) {
      return;
    }

    context.clearRect(0, 0, canvas.width, canvas.height);
    fillWithBlack(canvas, context);
  };

  useEffect(() => {
    const imageToEdit = new Image();
    imageToEdit.src = uploadedImageUrl;

    imageToEdit.onload = async () => {
      const width = imageToEdit.width;
      const height = imageToEdit.height;

      const canvas = canvasRef.current;
      if (canvas) {
        canvas.width = width;
        canvas.height = height;

        const previousMaskImage = new Image();
        previousMaskImage.src = maskBase64Png;
        previousMaskImage.onload = () => {
          const context = canvas.getContext('2d');
          if (!context) return;
          context.clearRect(0, 0, width, height);
          context.drawImage(previousMaskImage, 0, 0, width, height);
        };
      }

      if (hasDrawn) return;
      // The width and height of the image can be accessed here
      setSize({ width, height });

      await waitSeconds(0.4);
      clearCanvas(canvasRef.current!);
    };
  }, [uploadedImageUrl]);

  const getBase64Png = (canvas: HTMLCanvasElement): string => {
    // Create a temporary canvas with original dimensions
    const tempCanvas = document.createElement('canvas');
    const width = canvas.width;
    const height = canvas.height;
    tempCanvas.width = width;
    tempCanvas.height = height;

    const tempContext = tempCanvas.getContext('2d');
    if (tempContext) {
      // Draw the content of the current canvas (scaled) onto the temporary canvas
      // this is so if the image is scaled, the image is retained
      tempContext.drawImage(canvas, 0, 0, width, height);
    }

    const base64String = tempCanvas.toDataURL();
    return base64String;
  };

  return (
    <Container>
      <ImageContainer>
        <StyledCanvas ref={canvasRef} />
        {uploadedImageUrl ? (
          <StyledImage
            ref={imageRef}
            src={uploadedImageUrl}
            alt="Image to be edited"
          />
        ) : (
          <ErrorText />
        )}

        <ZoomCanvas ref={zoomCanvasRef} style={zoomCanvasStyle} />
      </ImageContainer>
      <InpaintingOptions
        brushSize={brushSize}
        setBrushSize={setBrushSize}
        handleClearCanvas={handleClearCanvas}
        handleUndo={handleUndo}
      />
    </Container>
  );
};

const fillWithBlack = (
  canvas: HTMLCanvasElement,
  context: CanvasRenderingContext2D,
) => {
  context.fillStyle = '#000000';
  context.fillRect(0, 0, canvas.width, canvas.height);
};

const ErrorText = () => (
  <div
    style={{
      color: 'red',
    }}
  >
    Error: Please select an image to edit
  </div>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${desktopMediaQuery} {
    flex-direction: row;
    align-items: center;
    gap: 64px;
  }

  align-items: center;
  justify-content: center;
`;

const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  align-self: center;
  max-height: 500px;
  max-width: 70vw;
`;

const StyledImage = styled.img`
  display: block;
  max-width: 100%;
  max-height: 500px;
  height: auto;
  border-radius: 4px;
  z-index: 1;
`;

const StyledCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 500px;
  height: auto;
  opacity: 0.35;
  border: 1px solid ${BORDER_COLOR};
  z-index: 2;
`;

const ZoomCanvas = styled.canvas`
  display: none;
  border: 1px solid ${BORDER_COLOR};
  border-radius: 16px;
  z-index: 11; // on top of TopBar as well
`;

export default DrawingCanvas;
