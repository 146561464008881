/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Title = styled.h1`
  font-size: 48px;
  margin-bottom: 16px;
`;

const Subtitle = styled.p`
  font-size: 24px;
  color: grey;
`;

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  const navigateToHome = () => {
  };

  return (
    <Wrapper>
      <Title>404</Title>
      <Subtitle>Page Not Found</Subtitle>
    </Wrapper>
  );
};

export default NotFound;
