import React from 'react';
import styled from 'styled-components';
import { useGeneralContext } from '../../../context/GeneralContextProvider';
import { Age, BodyType, Breasts } from '../../../types';
import {
  BACKGROUND_COLOR,
  PRIMARY_TEXT_COLOR,
  BORDER_COLOR,
  PRIMARY_COLOR,
} from '../../../constants';
import { desktopMediaQuery } from '../../../styleHelpers';

const SettingsPart = () => {
  const {
    uploadedImageUrl,
    age,
    setAge,
    breasts,
    setBreasts,
    bodyType,
    setBodyType,
  } = useGeneralContext();

  const handleChooseBreasts = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setBreasts(e.target.value as Breasts);
  };

  const handleChooseBodyType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setBodyType(e.target.value as BodyType);
  };

  const handleChooseAge = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAge(e.target.value as Age);
  };

  if (uploadedImageUrl === '') return null;

  return (
    <Container>
      <StyledLabel>Breasts:</StyledLabel>
      <StyledSelect onChange={handleChooseBreasts} value={breasts}>
        <option value="Small">Small</option>
        <option value="Medium">Medium</option>
        <option value="Large">Large</option>
      </StyledSelect>

      <EmptySpace />

      <StyledLabel>Body Type:</StyledLabel>
      <StyledSelect onChange={handleChooseBodyType} value={bodyType}>
        <option value="Petite">Petite</option>
        <option value="Average">Average</option>
        <option value="Athletic, fit">Athletic</option>
        <option value="Curvy">Curvy</option>
        <option value="Plus size, heavy">Plus Size</option>
      </StyledSelect>

      <EmptySpace />

      <StyledLabel>Age:</StyledLabel>
      <StyledSelect onChange={handleChooseAge} value={age}>
        <option value="20 years old">20 - 30</option>
        <option value="30 years old">30 - 40</option>
        <option value="40 years old">40 - 50</option>
        <option value="50 years old">50 - 60</option>
        <option value="65 years old">60+</option>
      </StyledSelect>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  margin-top: 64px;
  margin-bottom: 64px;

  width: 70vw;

  padding-left: 8px;
  padding-right: 8px;

  ${desktopMediaQuery} {
    flex-direction: row;
    align-items: center;
    gap: 16px;
    width: 600px;
  }
`;

const StyledLabel = styled.label`
  font-size: 16px;
  color: ${PRIMARY_TEXT_COLOR};
  margin-bottom: 8px;
`;

const EmptySpace = styled.div`
  height: 8px;
`;

const StyledSelect = styled.select`
  width: 75%;
  background-color: ${BACKGROUND_COLOR};
  height: 40px;
  font-size: 14px;

  font-weight: 400;
  color: ${PRIMARY_TEXT_COLOR};

  border-radius: 8px;
  border: 1.5px solid ${BORDER_COLOR};
  outline: none;
  padding: 8px;
  margin-top: -4px;

  transition: border-color 0.2s;
  ${desktopMediaQuery} {
    &:hover {
      cursor: pointer;
      border-color: ${PRIMARY_COLOR};
    }
  }
`;

export default SettingsPart;
