import React from 'react';
import { CardTitle } from '../../common/components/cardParts';
import styled from 'styled-components';
import PaymentComponent from '../../common/components/PaymentComponent';

const PricingInfo: React.FC = () => {
  return (
    <PricingContainer>
      <CardTitle>Pricing</CardTitle>
      <PaymentComponent onLandingPage />
    </PricingContainer>
  );
};

export default PricingInfo;

const PricingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  margin-top: 48px;
  margin-bottom: 48px;
`;
