import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DrawingCanvas from './DrawingCanvas';
import { useGeneralContext } from '../../../../context/GeneralContextProvider';
import {
  InstructionsContainer,
  InstructionsTitle,
  InstructionsText,
} from '../commonComponents';
import { FaPaintbrush } from 'react-icons/fa6';
import { MdAddCircle } from 'react-icons/md';
import { getIsOnDesktop } from '../../../../styleHelpers';
import { LOCAL_STORAGE_EXAMPLE_INPAINT_SEEN } from '../../../../constants';
import ExampleInpaintModal from '../../../../modals/ExampleInpaintModal';
import { FaBullseye } from 'react-icons/fa';

const InpaintPart = () => {
  const { uploadedImageUrl } = useGeneralContext();
  const [showExampleInpaintModal, setShowExampleInpaintModal] = useState(false);

  useEffect(() => {
    if (uploadedImageUrl === '') return;
    if (!localStorage.getItem(LOCAL_STORAGE_EXAMPLE_INPAINT_SEEN)) {
      setShowExampleInpaintModal(true);
    }
  }, [uploadedImageUrl]);

  if (uploadedImageUrl === '') {
    return null;
  }

  const isOnDesktop = getIsOnDesktop();
  const textToRender = isOnDesktop
    ? 'Use your mouse to paint over the areas you wish to undress in the image below'
    : 'Use your finger to paint over the areas you wish to undress in the image below';
  return (
    <Container>
      <InstructionsContainer>
        <InstructionsTitle>Step 2: Select area to Undress</InstructionsTitle>
        <InstructionsText>
          <p>
            <FaPaintbrush size={38} /> {textToRender}
          </p>
          <p>
            <MdAddCircle size={20} /> It's better to paint over too a bit much than
            too little
          </p>
          <p>
            <FaBullseye size={20} />
            The more closely you paint, the better results you get
          </p>
        </InstructionsText>
      </InstructionsContainer>

      <ExampleInpaintModal
        showModal={showExampleInpaintModal}
        setShowModal={setShowExampleInpaintModal}
      />

      <DrawingCanvas />
    </Container>
  );
};

const Container = styled.div`
  /* Add your CSS styles here */
`;

export default InpaintPart;
