class FirstTimeUserStorage {
  private storageKey: string;

  constructor() {
    this.storageKey = 'firstTimeUser';
  }

  isFirstTimeUser(): boolean {
    const isFirstTime = localStorage.getItem(this.storageKey);
    return isFirstTime ? JSON.parse(isFirstTime) : true;
  }

  setNotFirstTimeUser(): void {
    localStorage.setItem(this.storageKey, JSON.stringify(false));
  }
}

export default FirstTimeUserStorage;
