class ReferralStorage {
  private storageKey: string;

  constructor() {
    this.storageKey = 'referralId';
  }

  getReferralId(): string {
    const referralId = localStorage.getItem(this.storageKey);
    return referralId ? JSON.parse(referralId) : '';
  }

  setReferralId(referralId: string): void {
    localStorage.setItem(this.storageKey, JSON.stringify(referralId));
  }
}

export default ReferralStorage;
