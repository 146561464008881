import React from 'react';
import { FaArrowRotateLeft, FaBrush } from 'react-icons/fa6';
import styled from 'styled-components';
import { PRIMARY_COLOR, PRIMARY_TEXT_COLOR } from '../../../../constants';
import BrushSizeSetter from './BrushSizeSetter';

interface InpaintingOptionsProps {
  brushSize: number;
  setBrushSize: (size: number) => void;
  handleClearCanvas: () => void;
  handleUndo: () => void;
}

const InpaintingOptions: React.FC<InpaintingOptionsProps> = ({
  brushSize,
  setBrushSize,
  handleClearCanvas,
  handleUndo,
}) => {
  return (
    <InpaintingOptionsContainer>
      <BrushSizeSetter setBrushSize={setBrushSize} brushSize={brushSize} />
      <StyledButton onClick={handleClearCanvas}>
        <FaBrush />
        Clear Canvas
      </StyledButton>
      <StyledButton onClick={handleUndo}>
        <FaArrowRotateLeft />
        Undo
      </StyledButton>
    </InpaintingOptionsContainer>
  );
};

const InpaintingOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 16px;
  gap: 16px;
`;

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border: 1px solid ${PRIMARY_COLOR};
  background-color: transparent;
  color: ${PRIMARY_TEXT_COLOR};
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 0.3s;

  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);

  &:hover {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.7);
  }

  width: 100%;
`;

export default InpaintingOptions;
