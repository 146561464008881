import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useGetImageDataWithTrackId from './hooks/useGetImageDataWithTrackId';
import { waitSeconds } from '../../../helpers';
import { fadeIn, fadeInWithBounce } from '../../../common/animations';
import LoadingAnimation from './LoadingAnimation';
import PrimaryButton from '../../../common/components/PrimaryButton';
import { StyledImage } from './StyledImage';
import useAddImageToContextAndStorage from './hooks/useAddImageToContextAndStorage';
import { useGeneralContext } from '../../../context/GeneralContextProvider';
import { BlurredImage, UndressedImage } from '../../../types';
import useApi from '../../../common/hooks/useApi';
import { toast } from 'react-toastify';
import { trackUsedCredits } from '../../../analytics';
import { SECONDARY_TEXT_COLOR } from '../../../constants';

interface P {
  trackId: string;
}
const GeneratedImage = ({ trackId }: P) => {
  const [url, setUrl] = useState<string>('');

  const [isBlurred, setIsBlurred] = useState<boolean>(false);

  const {
    loggedInUserToken,
    setShowAuthModal,
    amountOfCredits,
    setAmountOfCredits,
    setShowBuyCreditsModal,

    fetchUserDataEveryNSeconds,
  } = useGeneralContext();

  const getImageDataWithTrackId = useGetImageDataWithTrackId();
  const addImageToContextAndStorage = useAddImageToContextAndStorage();
  const { apiCall: unblur, loading } = useApi('unblur', 'POST');

  useEffect(() => {
    const updateUrl = async () => {
      if (trackId === '') return;
      await waitSeconds(8);
      const newImgData = await getImageDataWithTrackId(trackId);
      if (newImgData === 'cancelled' || newImgData === null) {
        alert('Something went wrong, please try again. Contact support@ainudes.io to get your credits back. Sorry :(');
        return;
      }

      if (newImgData.showBlurredVersion) {
        const blurredImage = newImgData as BlurredImage;
        setUrl(blurredImage.blurredImageInBase64);
        setIsBlurred(true);
        return;
      } else {
        const nonBlurredImage = newImgData as UndressedImage;
        setUrl(nonBlurredImage.imageUrl);
        addImageToContextAndStorage(nonBlurredImage);
      }
    };
    updateUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackId]);

  const handleGoBack = async () => {
    window.history.back();
    await waitSeconds(0.3);
  };

  const handleRemoveBlur = async () => {
    if (!loggedInUserToken) {
      toast.error('Please sign up first');
      setShowAuthModal(true);
      return;
    }

    if (fetchUserDataEveryNSeconds === 10 && amountOfCredits < 1) {
      toast.error(
        'Not enough credits. Bought already? Please wait a minute for the credits to update.',
      );
      return;
    }

    if (amountOfCredits < 1) {
      toast.error('Not enough credits.');
      setShowBuyCreditsModal(true);
      return;
    }

    const resultOfUnblur = await unblur({ trackId, token: loggedInUserToken });
    if (resultOfUnblur.status === 200) {
      trackUsedCredits();
      setAmountOfCredits(amountOfCredits - 1);
      const { data: nonBlurredImage } = resultOfUnblur;
      setUrl(nonBlurredImage.imageUrl);
      addImageToContextAndStorage(nonBlurredImage);
      setIsBlurred(false);
    } else {
      toast.error('Something went wrong, please try again.');
    }
  };

  if (trackId === '') return null;
  if (url === '') return <LoadingAnimation />;

  return (
    <Container>
      <Image
        key={url}
        id={trackId}
        src={url}
        alt=" "
        /*
        // This hack is needed so the image when it's not ready on server actually loads when it is
        onError={async ({ currentTarget }) => {
          image.generating = true;
          currentTarget.onerror = null;
          setLoading(true);
          currentTarget.src = PLACEHOLDER_IMAGE_PATH;
          await sleep(3);
          setStateToForceUpdate((s) => s + 1);
          currentTarget.src = url;

          image.generating = false;
          setLoading(false);
        }}
        */
      />
      <ButtonContainer>
        {isBlurred ? (
          <PrimaryButton onClick={handleRemoveBlur}>
            {loading ? 'Loading...' : 'Remove blur'}
          </PrimaryButton>
        ) : (
          <PrimaryButton onClick={handleGoBack}>Try again</PrimaryButton>
        )}
      </ButtonContainer>
      <DidntGetResultsText>
        Didn't get the results you wanted? Make sure you followed the
        instructions on the previous page correctly. Sometimes, though, you just
        need to let the AI try again without changing anything
      </DidntGetResultsText>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 8px;
  margin-bottom: 64px;

  animation: ${fadeIn} 2s ease-in-out;
`;

const DidntGetResultsText = styled.p`
  width: 80vw;
  max-width: 400px;
  color: ${SECONDARY_TEXT_COLOR};
`;

const ButtonContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 4px;
  width: 90%;
`;

const Image = styled(StyledImage)`
  opacity: 0;
  transform: scale(0.98);
  animation: ${fadeInWithBounce} 0.4s ease-in-out forwards 1.1s; // Adding a delay before

  max-height: 75vh;
`;

export default GeneratedImage;
