import React from 'react';
import styled from 'styled-components';
import { PRIMARY_TEXT_COLOR, PRIMARY_COLOR } from '../../../../constants';

const AiNudesLogo: React.FC = () => {
  return (
    <Container>
      <GradientLogo>AI Nudes.io </GradientLogo>
      😈
    </Container>
  );
};
const Container = styled.div`
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const GradientLogo = styled.div`
  background: -webkit-linear-gradient(
    45deg,
    ${PRIMARY_TEXT_COLOR},
    ${PRIMARY_COLOR}
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export default AiNudesLogo;
