import React, { useState } from 'react';
import { useGeneralContext } from './context/GeneralContextProvider';

const OfflineAccess: React.FC = () => {
  const { setSiteOffline } = useGeneralContext();
  const [password, setPassword] = useState('');

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handlePasswordSubmit = () => {
    if (password === 'moonsOfJupiter') {
      setSiteOffline(false);
    } else {
      alert('Incorrect password');
    }
  };

  return (
    <div>
      <div>
        Site temporarily offline. If you have unused credits, please contact
        support@ainudes.io for refunds. Sorry!
      </div>
      <div>
        <input
          type="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder="Enter Admin password"
        />
        <button onClick={handlePasswordSubmit}>Submit</button>
      </div>
    </div>
  );
};

export default OfflineAccess;
