import { RingLoader } from 'react-spinners';
import styled from 'styled-components';
import { PRIMARY_COLOR } from '../../../constants';
import { useState, useEffect } from 'react';

const LoadingAnimation = () => {
  const textsToRender = [
    'Removing Clothes...',
    'Taking Off Layers...',
    'Undoing Buttons...',
    'Sliding Down Zippers...',
    'Peeling Off Outfits...',
    'Loosening Belts...',
    'Stripping Sleeves...',
    'Adjusting algorithms...',
    'Uncovering Secrets...',
    'Thinking about Neural Networks...',
    'Processing Pixels...',
  ];
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTextIndex(
        () => Math.floor(Math.random() * textsToRender.length),
      );
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const textToShow = textsToRender[currentTextIndex];
  return (
    <Container>
      <RingLoader color={PRIMARY_COLOR} size={100} speedMultiplier={0.7} />
      <Text>{textToShow}</Text>
    </Container>
  );
};

export default LoadingAnimation;

const Container = styled.div`
  width: 80vw;
  max-width: 500px;
  height: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-right: 24px;
`;

const Text = styled.div`
  margin-top: 24px;
  margin-left: 18px;
`;
