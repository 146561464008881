import styled from 'styled-components';
import { CARD_BACKGROUND_COLOR } from '../../constants';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 70vw;
  max-width: 450px;
  padding: 24px;

  margin-top: 64px;
  margin-bottom: 64px;

  background-color: ${CARD_BACKGROUND_COLOR};
  border-radius: 8px;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.45);
`;

export const CardTitle = styled.h3`
  font-size: 24px;
  margin-top: 0;
`;

export const CardText = styled.div`
  li {
    list-style-type: none;
    margin-bottom: 8px;
  }
`;

export const PricePart = styled.div`
  font-size: 24px;
  margin-bottom: 16px;
`;
